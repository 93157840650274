 
.tabs {
    list-style: none;
  margin: 0;
  display: flex;
  justify-content: flex-start; /* Ensure items start from the beginning */
    padding: 20px 48px 0px 48px !important;
    border: 1px solid #0065AC;
    background-color: #0065AC;
    color: #FFFFFF;
  }
  .isactive {
    background-color: #009DE0;
    padding: 17px 16px 16px 16px !important ;
    border-radius: 4px 4px 0px 0px;
    margin-bottom: -1px;
  }
 
  .tabs a {
    cursor: pointer;
    padding: 20px 48px 14px 48px !important;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 19px;
    color: #FFFFFF !important;
    text-decoration: none;
  }
 
  
  .tab-content {
    border: 1px solid #ddd;
    border-top: none;
    padding: 20px;
  }
  
  .tab-content .content {
    display: none;
  }
  
  .tab-content .content.active {
    display: block;
  }
  .hrcenter-layout{
margin-left: 48px;
margin-right: 48px;
  }

  
/* In your CSS file */
.right-align-container {
  display: flex;
  justify-content: flex-end;
  width: 100%; /* Ensure it spans the full width of the parent */
}