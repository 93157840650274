.accordion {
  max-width: 37.5rem;
  margin: 2rem auto;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.75rem 0rem;
  border-bottom: 0.06rem solid #00000033;
}

.accordion-content-item-padding {
  display: flex;
  white-space: initial;
  padding-bottom: 0.2rem !important;
}

.PetAssureNationcol-12 .accordion-content-item-padding {
  padding-bottom: 1.2rem !important;
}

.dropdown-item:last-child .accordion-title {
  border-bottom: none;
}

.dropdown-item {
  padding: 0px 24px;
  text-align: left;
  font: normal normal 600 14px/19px;
  letter-spacing: 0px;
  color: #767676 !important;
  opacity: 1;
  font-size: 0.9rem;
  cursor: pointer;
}

.dropdown-item.contactUs .accordion-content-item {
  padding-bottom: 0.25rem !important;
  padding-top: 0.25rem !important;
}

.dropdown-item.contactUs .accordion-content-item:last-of-type {
  padding-bottom: 0.5rem !important;
}

.dropdown-item-open.contactUs .accordion-title {
  border-bottom: none;
}

.dropdown-item.contactUs .accordion-text-no-image,
.dropdown-item.contactUs + .member-container + .dropdown-item .accordion-text-no-image {
  margin-left: 8px!important;
}

.dropdown-item:focus,
.dropdown-item:hover,
.accordion-image:hover {
  color: #2c6ef2 !important;
  background-color: #ffffff;
}

.dropdown-item-open {
  color: #202020 !important;
}

.accordion-image-container {
  display: flex;
  align-items: center;
}

.accordion-image {
  width: 1rem;
  height: 1rem;
  margin-right: 0.75rem;
}

.accordion-text {
  margin-left: 0.47rem;
}

.accordion-text-no-image {
  margin-left: 1.5rem;
}

.accordion-text-no-image-panel {
  margin-left: .75rem;
  font-size: 1.5rem;
}

.accordion-options {
  padding: 0.5rem 0rem;
  border-bottom: 0.061px solid #00000033;
  font-size: 0.9rem;
}

.accordion-item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
  font-size: 0.9rem;
  color: #002c77 !important;
}

.accordion-item:hover {
  color: #2c6ef2 !important;
  background-color: #ffffff;
  font-weight: 600;
}

.accordion-content {
  padding: 0 1.5rem;
  border-bottom: 0.0625rem solid #00000033;
}

.accordion-content>.accordion-content-item:first-child {
  padding-top: 0.75rem;
}

.accordion-content>.accordion-content-item:last-child {
  padding-top: 1rem;
  padding-bottom: 0.75rem;
}

.dropdown-item-open:hover,
.accordion-content:hover {
  color: #202020 !important;
}

.accordion-content-item {
  display: flex;
  white-space: initial;
  /*uncommented for collapse panel list */
  padding-bottom: 0.75rem;
}

.description {
  /* display: flex; */
  white-space: initial;
  /*uncommented for collapse panel list */
  padding-bottom: 0.75rem;
  font-family: NotoSansRegular;
  font-size: 0.875rem;
}

.description-span {
  /* display: flex; */
  white-space: initial;
  /*uncommented for collapse panel list */
  padding-bottom: 0.75rem;
  font-family: NotoSansRegular;
  font-size: 0.875rem;
}

.description-title {
  display: flex;
  white-space: initial;
  /*uncommented for collapse panel list */
  padding-bottom: 0.75rem;
  font-family: NotoSansRegular;
  font-size: 0.875rem;
  padding: 0rem 0rem 0rem 0rem
}

.ul-class {
  padding-right: 0rem !important;

  white-space: initial;
  /*uncommented for collapse panel list */
  padding-bottom: 0.75rem;
  font-family: NotoSansRegular;
  font-size: 0.875rem;
}

.ol-class {
  padding-right: 0rem !important;

  white-space: initial;
  /*uncommented for collapse panel list */
  padding-bottom: 0.75rem;
  font-family: NotoSansRegular;
  font-size: 0.875rem;
}

.accordion-content-item-padding {
  display: flex;
  white-space: initial;
  padding-bottom: 0.2rem !important;
}

.accordion-content-item-text {
  margin: 0 !important;
  font-size: 0.875rem;
  line-height: 1.2rem;
}

.accordion-content-item-title {
  font-weight: 600;
}

.accordion-image-container-left-side {
  float: left;
  position: absolute;
}

.text-block {
  position: relative;
  /* width: 93%; */
  /*ucommented */
  bottom: 2px;
  /* right:20px;  */
  background-color: #0077a0;
  border: 0.25rem solid #0077a0;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  color: white;
  text-align: center;
  /* padding-left: 20px;
      padding-right: 20px;  */
  font-size: 0.875rem;
}

.text-block1 {
  background-color: #0077a0;
  color: white;
  text-align: center;
  position: absolute;
  color: black;
  cursor: pointer;
  color: black;
  left: 1%;
  bottom: 38%;
  width: 93%;
}

.image-card-border-collapse {
  border-radius: 0.25rem;
  /* background-color: #f8fafc; */
  /*commented for double line*/
  /* border: solid 0.063rem #0077A0; */
  /* bottom:0px; */
  position: relative;
}

.panel {
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #d1e0ec;
  /* bottom:0px; */
  /* padding-top: 15px;
 padding-bottom: 35px;
 padding-left: 10px;
 padding-right: 10px; */
  font-family: NotoSansSemiBold;
  /* font-size: 0.625rem; */
}

.panel-links {
  font-family: NotoSansRegular;
  font-size: 0.875rem;
  padding-top: 48px;
  padding-bottom: 40px;
  line-height: 1.5rem;
  /* border-radius: 5px;
  background-color: #f8fafc;
 border: solid 1px #d1e0ec;
 bottom:0px;
 padding-top: 15px;
 padding-bottom: 20px;
 padding-left: 10px;
 padding-right: 10px; */
  /* font-family: NotoSansSemiBold; */
  /* font-size: 0.625rem; */
}

.collapse-border {
  border-radius: 5px;
  background-color: #f8fafc;
  border: solid 1px #d1e0ec;
  padding: 10px;
  bottom: 0px;
}

.play-icon {
  position: absolute;
  color: black;
  cursor: pointer;
  color: black;
  left: 40%;
  bottom: 30%;
}

.play-icon1 {
  /* text-align: center; */
  position: absolute;
  color: black;
  cursor: pointer;
  color: black;
  background-color: #0077a0;
  color: white;
  border-radius: 5px;
  /* left:40%; */
  bottom: 0px;
  /* right:20px; */
  padding-left: 0px;
  padding-right: 0px;
}

.overlay {
  height: 100%;
  width: 100%;
  opacity: 0.3;
  transition: 0.3s ease;
}

/* ul {
  list-style-type: disc !important;
  padding-left:1em !important;
  margin-left:1em;
  font-size: 0.875rem;
} */

.ul-class {
  list-style-type: disc !important;
  padding-left: 1em !important;
  margin-left: 1em;
  font-size: 0.875rem;
}

ul.hydro li {
  margin-bottom: 10px;
  font-family: 'Open Sans';
  color: #898989;
}

.listHeader {
  font-size: 0.875rem;
}

/* .container {
  position: relative;
  font-family: Arial;
}
 */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  #urlLink {
    font-size: 0.7rem;
    /* padding-bottom: 2rem; */
  }

  #panel {
    padding-bottom: 1.4rem !important;
  }
}

.text-bold-collapse .accordion-text-no-image {
  font-weight: bold !important;
}

@media only screen and (max-device-width: 768px) {
  .videoMobile {
    margin-top: 1rem;
  }
}

.mediaContainer {
  display: flex;
  height: 100%;
}

.row.py-3.mediaContainer {
  padding-bottom: 0 !important;
}

.mediaContainer .col-11.col-lg-4.col-md-5.py-3 {
  height: 100%;
}

.mediaContainer .col-11.col-lg-4.col-md-5.py-3:has(.panel) {
  height: auto;
}

.mediaContainer .col-11.col-lg-4.col-md-5.py-3 .panel {
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .videoDialog {
    max-width: 23rem !important;
  }

  .videoDialog {
    max-width: 25rem !important;
    padding-left: 22rem !important;
    padding-right: 0.5rem !important;
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 768px) {
  .videoDialog .modal-content {
    min-height: 500px;
  }

  .videoDialog #iframeProductVideo {
      position: absolute;
      top: 0px;
      left: 0;
      width: 100%;
      height: 100%;
    }
}
.PetAssureNationcol-12 .accordion-content-padding>.accordion-content-item:first-child,
.PetAssureNationcol-12 .accordion-content-padding>.accordion-content-item:nth-child(3) {
  padding-top: 0.75rem;
  padding-bottom: 0;
}

.PetAssureNationcol-12 .accordion-content-padding>.accordion-content-item:nth-child(5),
.PetAssureNationcol-12 .accordion-content-padding>.accordion-content-item:nth-child(6),
.PetAssureNationcol-12 .accordion-content-padding>.accordion-content-item:nth-child(7) {
  padding-top: 0.2rem;
  padding-bottom: 0;
}

@media only screen and (max-device-width: 480px) {
  #iframeProductVideo {
    height: 100px;
    width: 250px;
  }
}

@media (min-device-width: 480px) and (max-width: 768px) {
  #iframeProductVideo {
    height: 200px;
    width: 650px;
  }
}

.product-logo-fixed-size {
  max-height: 64px;
  max-width: 200px;
}