.changebox-general-items span {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
}

.changebox-general-items-right span {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: normal;
    text-align: left;
    color: #00a190;
}

.label-header {
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: normal;
    text-align: left;
    color: #666666;
}

.header-text {
    font-family: Roboto;
    font-size: 32px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.98;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}

.error-message {
    margin-top: 0rem;
    padding-top: 0.001rem;
    font-size: 0.75rem;
    font-family: NotoSansRegular;
}

.panelStyle {
    background-color: #FCFCFC !important;
    border-radius: 0.3rem !important;
    padding: 1rem 1rem 1rem 1rem !important;
    border: 0.063rem solid #E1E1E1;
}

.panelStyleNoBorder {
    background-color: transparent !important;
}

.panelStyleItem {
    padding: 1rem 0 !important;
}

.outerDiv {
    background-color: red !important;
    border-radius: 0.3rem !important;
    /* border: 0.063rem solid #E1E1E1; */

}

.con {
    /* padding-top padding-right padding-bottom padding-left */
    padding-bottom: 3rem 2rem 3rem 4rem !important;
}


.hide-scrollbar {
    overflow-x: hidden;
}

.hideWhenNoChild:empty {
    display: none;
}

.hideWhenNoChild>div:empty {
    display: none;
}

@media (max-width: 600px) {

    .panelStyleNoBorder .hideWhenNoChild:not(:first-of-type) .btn.mt-4,
    .panelStyleNoBorder .hideWhenNoChild:not(:first-of-type) .btn.mt-5 {
        margin-top: 1rem !important;
    }

    .column-1-3 {
        width: 100%;
    }
}

.panelStyleNoBorder.hide-element .hideLabel label {
    display: none;
}

.panelStyleNoBorder.hide-element .form-side-header-contact {
    display: none;
}

.panelStyleNoBorder.hide-element .selectContainer {
    display: none;
}

.sticky-note:has(.customSticky1) {
    background-color: rgb(240, 250, 255);
    font-size: 0.75rem;
    border: 1px solid rgb(159, 224, 255);
}

.employmentStatus {
    padding-right: 0 !important;
}

.addressContent {
    padding: 1rem 0 1.5rem 1rem;
}

.contactLabel {
    min-width: 7rem;
}

.col-md-2.primaryAddress {
    min-width: 100%;
}

.row.primaryAddress {
    min-width: 100%;
    border-bottom: 0.063rem solid #E1E1E1;
    padding-bottom: 2rem !important;
    position: relative;
}
.row.primaryAddress .col-1{
    position: absolute;
    left: 12rem;
    top: -0.25rem;
}

.primaryAddress label {
    width: 1037px;
    font-family: NotoSansMedium;
    font-size: 1.25rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: left;
    color: #1e2e5d;
    margin-bottom: 1.5rem;
}

svg {
    width: 1.5rem;
    height: 1rem;
}

.icon-button {
    border: none;
    background-color: transparent;
    padding: 0;
    margin-right: 1rem;
}

.form-btnGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 1rem 0;
    /* position: absolute;
    bottom: 0rem; */
}

.form-btnGroup button {
    min-width: 10rem;
}

.form-btnGroup .cancelBtn {
    margin-right: 1rem;
}

.employeeProfileWrapper .required-field::after {
    content: '*';
    color: #C53532;
    position: absolute;
    top: 0;
    right: 9.5rem;
}
.manageProfileWrapper .required-field::after {
    content: '*';
    color: #C53532;
    position: absolute;
    top: 0;
    right: 10rem;
}
.employeeProfileWrapper .required-field-phone::after
{
    content: '*';
    color: #C53532;
    position: absolute;
    top: 0;
    right: 9.9rem;
}
.manageProfileWrapper .required-field-phone::after {
    content: '*';
    color: #C53532;
    position: absolute;
    top: 0;
    right: 10.4rem;
}

.employeeProfileWrapper .forSSN::after,
.manageProfileWrapper .forSSN::after {
    content: '*';
    color: #C53532;
    position: absolute;
    top: 1.1rem;
    left: 2rem;
}
.floatingLabelProfile input {
    padding: 0.625rem;
    display: block;
    background-color: transparent;
    border: none;
    /* border-bottom: 0.0001rem solid #ccc; */
    height: 2.5rem !important;
    border-style: solid !important;
    border-width: 0.001rem !important;
    border: #DADADA;
    border-bottom: #DADADA;
}


.floatingLabelProfile input {
    padding: 0.625rem;
    display: block;
    background-color: transparent;
    border: none;
    /* border-bottom: 0.0001rem solid #ccc; */
    height: 2.5rem !important;
    border-style: solid !important;
    border-width: 0.001rem !important;
    border: #DADADA;
    border-bottom: #DADADA;
}

.employeeProfileWrapper .form-side-big-header span,
.manageProfileWrapper .form-side-big-header span {
    color: #C53532;
}

.employeeProfileWrapper .alternatePhone,
.employeeProfileWrapper .alternateEmail,
.manageProfileWrapper .alternatePhone,
.manageProfileWrapper .alternateEmail {
    min-height: 5.5rem;
}

.employeeProfileWrapper .alternatePhone .form-mandatory,
.employeeProfileWrapper .alternateEmail .form-mandatory,
.manageProfileWrapper .alternatePhone .form-mandatory,
.manageProfileWrapper .alternateEmail .form-mandatory {
    min-width: 20rem !important;
    max-height: 1.5rem;
    font-size: 14px !important;
    padding: 0 !important;
    margin-top: 0.25rem;
}
@media only screen and (min-width: 780px){
.employeeProfileWrapper .forEmail .floatingLabel:not(:first-of-type),
.manageProfileWrapper .forEmail .floatingLabel {
    /* margin-top: 1rem; */
}
}
.employeeProfileWrapper .labelForPhone ,
.manageProfileWrapper .labelForPhone  {
    padding-top: 0rem;
}
@media only screen and (min-width: 780px){
    .row.forPhone,
    .row.forEmail{ max-height:6rem;}
}

@media only screen and (max-width: 780px){
    .row.forPhone .col-sm-6,
    .row.forEmail .col-sm-6{ padding: 0!important;}
}

.row.forPhone,
.row.forEmail{
    padding-left:1rem;
}

.row.surplus{
    background-color: #F8FAFC;
    font-weight: bold;
}
.excessLiability .carrierLogoClass img{
 height: 10rem;
 max-height: 10rem;
}
.excessLiability .carrierLogoClass{
    max-height: 5rem;
}
.row.borderBottom{
    border-bottom: 1px solid #D1E0EC;
}
.hide-col-4.col-md-4 {
    flex: 0 0 0%!important;
    max-width: 0%!important;
}
