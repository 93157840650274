.radio-button-group.forMFA input{
    margin-right: 0.5rem;
    margin-top: 0.25rem;
}

.radio-button-group.forMFA .radioTitle{
    margin: 0.5rem 0;
}

.radio-button-group.radio-inline {
    display: flex;
}

.radio-button-group.radio-inline label {
    width: 100%;
    display: block;
}
