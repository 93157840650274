.tooltipWrapper{
    position: relative;
}

.tooltipContent {
    visibility: visible;
    background-color: #EDF9FC;
    color: #202020;
    font-size: 0.8rem;
    font-family: inherit;
    padding: 1rem 0.5rem;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: -10rem;
    right: -16.5rem;
    opacity: 1;
    transition: opacity 0.3s;
    min-width: 15rem;
    max-width: 15rem;
}

.tooltipContent ul {
    margin-bottom: 1rem;
    color: rgb(0, 66, 128);
    font-weight: bold;
    font-size: 12pt;
}

.tooltipContent li {
    color: rgb(32, 32, 32);
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0.25rem;
}

.tooltipContent li:first-child {
    margin-top: 0.5rem;
}

.tooltipContainer:hover .tooltipContent {
    visibility: visible;
    opacity: 1;
}

.tooltipContainer img {
    position: absolute;
    right: -1rem;
    top: 2.5rem;
}

.toolTip:hover {
    filter: brightness(200%);
}

.toolTipBorder {
    border: 2px solid #9FE0FF;
    border-radius: 4px;
}