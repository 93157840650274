.addMultiple .action {
  color: #1a88e2;
  cursor: pointer;
}
.headerRadius {
  border-radius: 4pt 4pt 0pt 0pt;
}
.mobileTableBorder {
  border: 1pt solid #dadada;
  border-radius: 0pt 0pt 4pt 4pt;
}
.mobileTableBorderBottom {
  border-bottom: 1pt solid #dadada;
}
.mobileTableBorderBottom:last-child {
  border-bottom: none;
}
.subtitleCollapse {
  font-weight: 600;
  color: #202020;
  margin-top: 0.5rem;
}
.coverage-data {
  background-color: #f9f9f9;
  border-color: #dadada;
}
.waive-coverage {
  background-color: #f9f9f9;
  border-color: #dadada;
  width: 100%;
  height: 3rem;
  padding-top: 1rem;
  text-align: center;
}

.pet-mobile {
  text-align: right;
  margin-left: 8%;
}
.pet-table-allign {
  width: 2%;
}
.pet-cost-per-day {
  margin-left: 18%;
}

.member-container .modal-header {
  background: #0065AC;
  height: 60px;
  padding: 18px 30px 18px;
  border-radius: 4px 4px 0px 0px !important;
}

.member-container .modal-content {
  border-radius: 4px 4px 0px 0px !important;
  border: 0;
  background: #ffffff;
}

.member-container .modal-header {
  background: #0065AC;
  height: 60px;
  padding: 18px 30px 18px;
  border-radius: 4px 4px 0px 0px !important;
}

.modal-body {
  padding: 0px 32px 40px 32px;
}

.member-container .modal-footer {
  justify-content: center;
}
.member-container .modal-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
  color: #ffffff;
}

.btn-close{
  background: #0065AC;
  border: none;
  color: #ffffff;
}

.member-container .modal-content {
  border-radius: 4px 4px 0px 0px !important;
  border: 0;
  background: #ffffff;
}

.member-container .modal-header {
  background: #0065AC;
  height: 60px;
  padding: 18px 30px 18px;
  border-radius: 4px 4px 0px 0px !important;
}

.modal-body {
  padding: 0px 32px 40px 32px;
}

.member-container .modal-footer {
  justify-content: center;
}

.member-container .modal-title {
  font-size: 18px;
  font-weight: normal;
  color: #ffffff;
}

.closeIcon {
  width: 1.144rem;
  height: 1.144rem;
  margin: 7px 10px 5px 5px;
}

.scrollbar {
  overflow-y: auto;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
}

.model_confirmation_content {
  margin: 0 0 32px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
}
.model_confirmation_image {
  margin: 32px 21px 0;
  padding: 5.7px 6.3px 6.3px 5.7px;
  font-family: FontAwesome5Pro-Solid;
  font-size: 110px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #72a800;
}

.modalBodyMessage {
  height: auto;
  margin-right: 1.875rem;
  margin-top: 1.875rem;
}

.modalDisclaimer {
  padding: 32px 32px 0px 32px;
}
.modalDisclaimerText {
  color: #C53532;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 24px;
}
.modalDescription {
  padding: 0px 32px;
}
.modalDescriptionText {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  margin-bottom: 0px;
}


@media only screen and (max-width: 767px) {
  .deleteConfirmDialog{
    max-width:23rem !important;
  }
  .deleteConfirmDialog{
    max-width:25rem !important;
    padding-left: 22rem !important;
    padding-right: 0.5rem !important;
    margin-top: 2rem;
  }
}