.custom-checkbox input[type='checkbox'] {
    /* Hide the default checkbox */
    opacity: 0;
    position: absolute;
  }
   
  .custom-checkbox label {
    /* Create a box for the custom checkbox */
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    line-height: 22px;
    display: inline-block;
    font-size: 0.875rem;
    color: #202020;
    font-family: NotoSansMedium;
  }
   
  .custom-checkbox label:before {
    /* This is the custom square box */
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid rgb(226, 231, 235);
    background-color: #fff;
    transition: background 0.3s;
  }
   
  .custom-checkbox input[type='checkbox']:checked + label:before {
    /* Change background of box when checked */
    background-color: #667eea;
  }
   
  



  .custom-checkbox label:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 7px;
    width: 6px;
    height: 10px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    display: none;
}
   
  .custom-checkbox input[type='checkbox']:checked + label:after {
    /* Show checkmark when checked */
    display: block;
  }