.split {
    /* height: 100%; */
     width: 50%;
    position: absolute;
    /* z-index: 1; */
    top: 0;
    /* overflow-x: hidden; */
    /* padding-top: 150px; */
}
/* Control the left side */
.left {
    left: 0;
    /* background-color: #111; */
}

/* Control the right side */
.right {
    right: 0;
    /* background-color: red; */
}

/* .floatingLabel .required {
    border-bottom: 0.0625rem solid !important;
} */


.floatingLabelUser input {
    padding: 0.625rem;
    display: block;
    background-color: transparent;
    border: none;
    /* border-bottom: 0.0001rem solid #ccc; */
    height: 2.5rem !important;
    border-style: solid !important;
    border-width: 0.001rem !important;
    border: #DADADA;
    border-bottom: #DADADA;
}

.border {
    padding-top: 150px
}

.floatingLabelUser pb-3 + .error-message +.floatingLabelUser pb-3{
   border: red
}

.toast-message {
   width:5000rem !important;
    border: '1px solid #E6A7A3';
            padding: '16px';
            color: '#AB2F26';
            background: '#FFE8E6';
   /* height:5000rem !important */
}

.addForm {
    padding-top: 0rem !important
}

/* .form-row {
    margin-left: 50px !important;
    padding-top: 10px
} */

img#retrieve-user-image {
    min-width: 32rem;
    min-height:25rem;
}
@media (max-width:629px) {
    img#retrieve-user-image {
        display: none;
    }

    .form-row {
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 0px
    }
    .loginHeader {
        font-size: 1.5rem !important;
    }

    input {
        border: solid 0.0625rem #767676
    }
}


/*IPad Mini*/
@media only screen and (width: 768px) and (height: 1024px) and (orientation:portrait) {
    img#retrieve-user-image {
        min-width: 21rem;
        min-height:17rem;
    }
}
@media all and (width: 1024px) and (height: 768px) and (orientation:landscape){
    img#retrieve-user-image {
        min-width: 21rem;
        min-height:17rem;
    }
  }

/*IPad Air*/
@media only screen and (width: 820px) and (height: 1180px) and (orientation:portrait) {
    img#retrieve-user-image {
        min-width: 21rem;
        min-height:17rem;
    }
}
@media all and (width: 1180px) and (height: 820px) and (orientation:landscape){
    img#retrieve-user-image {
        min-width: 21rem;
        min-height:17rem;
    }
  }
  /*IPad Pro*/
@media only screen and (width: 1024px) and (height: 1366px) and (orientation:portrait) {
    img#retrieve-user-image {
        min-width: 21rem;
        min-height:17rem;
    }
}
@media all and (width: 1366px) and (height: 1024px) and (orientation:landscape){
    img#retrieve-user-image {
        min-width: 21rem;
        min-height:17rem;
    }
  }
/* ipad pro portarit*/
@media (max-width:1024px) {
    img#retrieve-user-image {
        /* width: 100%;
        height:90% */
    }
    input {
        border: solid 0.0625rem #767676
    }
}
/* ipad air landscape*/
@media (max-width:1180px) {
    img#retrieve-user-image {
        /* width: 95%;
        height:90% */
    }
    input {
        border: solid 0.0625rem #767676
    }
}
/* ipad air portrait*/
@media (max-width:820px) {
    img#retrieve-user-image {
        /* width: 100%;
        height: 60%; */
    }
    input {
        border: solid 0.0625rem #767676
    }
}
/* ipad mini landscape*/
@media (max-width:768px) {
    img#retrieve-user-image {
        /* width: 100%;
        height: 60%; */
    }
    input {
        border: solid 0.0625rem #767676
    }
}

/* @media (max-width: 992px) {
    #dualcolumn {
        .className ="col-md-12"
    }
} */

/* If you want the content centered horizontally and vertically */
/* .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
} */

/* Style the image inside the centered container, if needed */
/* .centered img {
    width: 150px;
    border-radius: 50%;
} */


/* @media (min-width: 768px) {
    .pr-md-5,
.px-md-5 
{
padding-right: 0rem !important;
}
}
     */

         /* @media (min-width: 768px) {
        .pr-md-5,
    .px-md-5 
    {
    padding-left: 0rem !important;
    }
    } */
        

        a {
            color: #002C77 !important;
        }



