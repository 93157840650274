.config .row {
    margin-right: 0px;
}

.no-padding-right {
    padding-right: 0 !important; 
    padding-left: 1 !important; 
    /* the !important rule is optional, but it can help ensure your styles are applied */
}

.config input[type=checkbox] {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.25rem;
    /* border: 0.0625rem solid rgb(204, 204, 204);
    backgroundColor: 'rgb(255, 255, 255)'; */
}

.inputConfig .text-input input {
    /* Your custom styles here */
    padding: 0.5rem;
    background-color: #fff;
    /* ...other styles... */
  }

  .inputConfig .text-input label {
    margin-bottom: 0.0rem !important;
    display: inline;
  }

  .inputConfig .text-area-wrapper textarea{
   width: 100% !important;
  }

  .hideLabel .text-input label {
    display: none;
  }