.enrollment-history__title {
    font-size: 24px;
    font-weight: 600;
    color: #202020;
    margin-bottom: 20px;
}

.enrollment-history__item {
    display: flex;
    padding: 20px;
    font-size: 14px;
    flex-wrap: wrap;
    color: #202020;
    border-radius: 4px;
    margin-bottom: 20px;
    justify-content: left;
    background-color: #F9F9F9;
    border: 1px solid #DADADA;
}

.enrollment-history__item__col {
    margin-right: 2rem;
}

.enrollment-history__item__label {
    margin-bottom: 0.5rem;
}

.enrollment-history__item__value {
    font-weight: 600;
}

.enrollment-history__item-button {
    width: 25px;
    border: none;
    margin-right: 20px;
    background-color: transparent;
}

.enrollment-history__item-button img {
    width: 100%;
}

.enrollment-history__change-description {
    width: 100%;
    margin-top: 20px;
    color: #202020;
}

.enrollment-history__change-description-title {
    margin: 1rem 0;
    font-size: 20px;
    font-weight: 600;
}

.history__composite-document,
.history__table-property-document,
.history__table-document {
    margin-bottom: 1rem;
}


/* Composite Document */
.history__composite-document__title {
    font-size: 1rem;
    font-weight: 700;
}


/* Table Property Document */
.history__table-property-document {
    display: flex;
    flex-wrap: wrap;
}

.history__table-property-document__title {
    font-size: 1rem;
    font-weight: 600;
    width: 100%;
    margin-bottom: 1rem;
}

.history__table-property-document__property {
    margin-right: 1.5rem;
}

.history__table-property-document__property-label {
    margin-bottom: 0;
}

.history__table-property-document__property-value {
    font-size: 16px;
    font-weight: 600;
}

/* Table Document */
.history__table-document__title {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.history__table-document__table {
    width: 100%;
    display: table;
    border-radius: 4px;
    margin-bottom: 1rem;
    border-collapse: collapse;
}

.history__table-document__table>thead>tr {
    color: #fff;
    background-color: #0065AC;
}

.history__table-document__table>thead>tr>th,
.history__table-document__table>tbody>tr>td {
    padding: 1rem 0.75rem;
    border: 1px solid #dadada;
}
.history__table-document__table>tbody>tr>td {
    background-color: #fff;
}
