.selectContainer {
  padding-top: 0.2rem;
  position: relative;
}

.selectContainer label {
  margin-bottom: 0.45rem;
}

.selectContainer .selectText {
  font-family: inherit;
  background-color: transparent;
  padding: 0.525rem 0.625rem 0.525rem 0.325rem;
  font-size: 0.875rem;
  border-radius: 3px;
  border: 0.063rem solid #dadada;
  /* border-bottom: 0.0625rem solid #dcdcdc; */
  /* border-bottom: 0.0625rem solid #dcdcdc; */
}

.selectContainer .isRequired {
  
  /* commented by Nisreen border-bottom: 0.0625rem solid #f9b44d !important; */
}

.selectContainer .isDisabled {
  background-color: #f3f3f3 !important;
}
select[disabled]{
  background: #EBEBE4  !important;
}
.selectContainer .selectLabel {
  font-size: 0.875rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0.25rem;
  top: 1rem;
  transition: 0.2s ease all;
  color: #999 !important;
}

.selectContainer .isFocused {
  transform: translateY(-1.5rem) scale(1);
}

.selectContainer .selectText:focus {
  outline: none;
  box-shadow: 0 0 0.5rem #eee;
  background-color: inherit;
}

.addOnWrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.addOnWrapper .selectContainer {
  padding-top: 0;
  position: relative;
}

.addOnWrapper .selectContainer .selectText {
  font-family: inherit;
  background-color: transparent;
  padding: 1rem 0.625rem 1rem 0;
  font-size: 0.875rem;
  border-radius: 0;
  border: 0.0625rem solid #dcdcdc;
}
option {
  padding: 15px 10px !important;
}

.selectContainer span {
  /* color: red; */
  color: #C53532;
}
