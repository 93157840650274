.toolTip:hover {
  filter: brightness(200%);
}
.toolTipBorder{
  border:2px solid #9FE0FF;
  border-radius:4px;
}
/* @media only screen and (min-width: 1200px) { 
  img.forAddress{
    position: absolute;
    left: -12rem;
    top: -4px;
  }
 } */


@media only screen and (max-width: 820px) {
img.forAddress {
  margin-top: 1rem!important;
  margin-left: 2.5rem;
}
}