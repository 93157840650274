.tableClient{
  background-color:#FAFAFA;
}

.heading{
  font-family: NotoSansLight;
  font-size: 1.5rem;
}
.error-message{
  color: red;
}
@media(max-width: 414px) {
  button.full-width {
      width: 100%;  
  }
}

.selectContainer {
  padding-top: 0.2rem;
  position: relative;
}

.selectContainer label {
  margin-bottom: 0.40rem;
}

.selectContainer .selectText {
  font-family: inherit;
  background-color: transparent;
  padding: 0.525rem 0.625rem 0.525rem 0.325rem;
  font-size: 0.875rem;
  /* border-radius: 0;*/
  border: 0.063rem solid #dadada;
  /* border-bottom: 0.0625rem solid #dcdcdc; */
  /* border-bottom: 0.0625rem solid #dcdcdc; */
}

.selectContainer .isRequired {
  
  /* commented by Nisreen border-bottom: 0.0625rem solid #f9b44d !important; */
}

.selectLifeEvent .selectText {
  min-height: 2.5rem;
}

.selectLifeEvent .selectContainer label {
  margin-bottom:0.1rem;
}

.selectContainer.selectContainer .isDisabled {
  padding-top: 7px;
}

.selectContainer .selectLabel {
  font-size: 0.875rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0.25rem;
  top: 1rem;
  transition: 0.2s ease all;
  color: #999 !important;
}

.selectContainer .isFocused {
  transform: translateY(-1.5rem) scale(1);
}

.selectContainer .selectText:focus {
  outline: none;
  box-shadow: 0 0 0.5rem #eee;
  background-color: inherit;
}

/* .current-address-left-card {
  border-radius: 4px;
  border: solid 1px #e1e1e1;
  background-color: #fcfcfc;
  height: 200px;
}

.current-address-right-card {
  border-radius: 4px;
  border: solid 1px #e1e1e1;
}

.current-name-left-card {
  border-radius: 4px;
  border: solid 1px #e1e1e1;
  background-color: #fcfcfc;
}

.current-name-right-card {
  border-radius: 4px;
  border: solid 1px #e1e1e1;
  width: 1000px;
  background: red !important;
  margin-left: 20px;
}

.headerTableTemp{
  background-color: #0065AC;
    color: #FFFFFF;
}


.form-row {
  margin-left: 30px !important;

}

.row {
  margin-left: 30px !important;

}

.changebox-general-items span {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.changebox-general-items-right span {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.9;
  letter-spacing: normal;
  text-align: left;
  color: #00a190;
}

.left-card-style {
  margin-left: -15px;
}

.form-side-header{
  /* font-family: Noto Sans; */
  /* font-size: 18px;
  font-weight: light;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.73;
  letter-spacing: normal;
  text-align: left;
  color: #1e2e5d;

}

.label-header{
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.03;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
}

.header-text {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.98;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.hide-scrollbar{
  overflow-x: hidden;
}

#iframe1 {
 
  min-height: 300px;
  }
  


@media (min-width: 992px){
   .col-lg-6 {
    flex: 0 0 100%; 
   max-width: 100%; 
}
}

.error-message{
  color: "";
  font-size: 0.75rem;
}


@media (min-width: 992px) {

.offset-lg-1 {
  margin-left: 0% !important;
}
}

.row {
  margin-left: 70px !important;
} */

.searchEmployeeWrapper .searchComponentContainer {
  /* background-color: #f9f9f9; */
  background-color: #ffffff;
}

 .configClassRow .row {
  margin-right: 0px !important;

}