.products-list {
    padding: 0;
}

.products-list .title {
    font-size: 16px;
    line-height: 2rem;
    font-family: NotoSansMedium;
}

.products-list .select-all {
    margin-bottom: 1rem;
}

.products-list .select-all label {
    line-height: 22px;
    vertical-align: top;
    margin-left: 0.5rem;
}

.products-list input {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.25rem;
    background-color: #fff;
    border: 0.0625rem solid #ccc;
}
.products-list input:focus {
    outline: #9cd9e4;
}

.products-list .product {
    display: flex;
    margin-bottom: 2rem;
    border: 1px solid #9cd9e4;
}

.products-list .product .product-selection {
    flex: 1;
    padding: 20px;
    background-color: #ffffff;
}

.product-selection .dependent-info {
    display: flex;
}

.product-selection .dependent-info > p {
    margin: 0;
}

.product-selection .selection {
    display: flex;
    margin-bottom: 1rem;
}

.product-selection .dependent-info > *:first-child,
.product-selection .selection > *:first-child {
    margin-right: 2rem;
}

.products-list .product .product-details {
    flex: 2;
    padding: 20px;
    background-color: #f0faff;
    border-left: 1px solid #9cd9e4;
}


.product-details .icon-button {
    white-space: nowrap;
    background: none;
    border: none;
    padding: 0;
}

.product-details .icon-button>div {
    margin-right: 10px;
    display: inline-block !important;
}

.product-details .title {
    color: #004c6c;
    margin-bottom: 1rem;
    font-size: 1.25rem;
}

.product-details .description {
    padding: 0;
    color: #202020;
    font-size: .875rem;
    font-family: NotoSansRegular;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
