.flicard-display-area {
  display: flex;
}

.flip-card {
  width: 450px;
  height: 360px;
  cursor: pointer;
  perspective: 1000px;
  margin-right: 1.5rem;
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  transition: transform 0.4s;
  transform-style: preserve-3d;
}

.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #F0FAFF;
  color: black;
  border: 1px solid #9FE0FF;
  border-radius: 4px;

}

.flip-card-back {
  background-color: #F0FAFF;
  color: white;
  border: 1px solid #9FE0FF;
  border-radius: 4px;
  transform: rotateY(180deg);

}

.heading {
  font-family: NotoSansSemiBold;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 21px;
  color: #202020;
  padding-top: 24px;
}

.flip-icon {
  float: right;
  width: 6%;
  margin-top: 15px;
  margin-right: 12px;
}

.employee-count {
  /* height: 45px; */
  background-color: #C7EDFF;
  margin: 20px 30px 0px 30px;
  border-radius: 4px;
  padding: 12px 0px;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  font-family: NotoSansSemiBold;
  right: 0px;
  left: 0px;
  position: absolute;
  bottom: 1rem;
}

.chart-data-info {
  overflow: auto;
  color: #202020;
  max-height: 16rem;
  margin: 2rem 1.5rem;
}

.chart-data-info .chart-data-indicator {
  width: 14px;
  margin: 5px;
  height: 14px;
  border-radius: 14px;
  display: inline-block;
}

.chart-data-info .chart-data {
  padding: 0 8px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.chart-data-info .chart-data+.chart-data:before {
  content: '';
  position: absolute;
  width: 1px;
  background-color: #222;
  height: 14px;
  top: 5px;
  left: 0;
}