.side-navigation {
  height: 100%;
}

.side-navigation ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.side-navigation ul>li {
  display: block;
  position: relative;
  padding-bottom: 0.5rem;
}

.side-navigation ul>li:not(:last-child):before {
  width: 2px;
  top: 2.25em;
  content: '';
  bottom: -2px;
  left: 1.2rem;
  position: absolute;
  background-color: #E2E2E2;
}

.side-navigation ul>li>.step {
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.step>.step-indicator {
  color: #fff;
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  margin-right: 0.625rem;
  line-height: 2.25rem;
  display: inline-block;
  padding: 0.25rem;
  position: relative;
  vertical-align: middle;
  color: #595959;
  background-color: #C2C2C2;
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.step>.step-indicator>span {
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  z-index: 3;
  content: '';
  font-weight: 600;
  position: absolute;
  color: #B3B3B3;
  background-color: #fff;
  -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.step.step-in-progress>.step-indicator {
  color: #0065AC;
  background-color: #0065AC;
}

.step.step-in-progress>.step-indicator>span {
  color: #0065AC;
  background-color: #fff;
}

.step.step-completed>.step-indicator,
.step.step-completed>.step-indicator>span {
  color: #fff;
  background-color: #14853D;
}

.step.step-completed,
.sub-content-item.link.sub-step-completed>.item-displayname {
  color: #565656;
}

.side-navigation ul>li>.step.step-draft,
.sub-content-item.link.sub-step-draft>.item-displayname {
  color: #B3B3B3;
  cursor: not-allowed;
}

.step.step-in-progress,
.sub-content-item.link.sub-step-in-progress>.item-displayname {
  color: #565656;
}


.step>.step-title {
  font-size: 16px;
  font-weight: bold;
}

.step-sub-content {
  margin-top: 0.5rem;
  justify-content: space-between;
  margin-left: 3.325rem;
}

.step-sub-content>.sub-content-item {
  padding: 0;
  border: none;
  display: block;
  color: #202020;
  text-align: left;
  margin-bottom: 5px;
  outline: none !important;
  transition: ease 0.3s color;
  background-color: transparent;
}

.sub-content-item>.item-displayname {}

.step-sub-content>.sub-content-item.link {
  font-weight: bold;
}

.sub-content-item.link .detail {
  font-weight: 400;
}

.sub-content-item.link:hover {
  color: #2C6EF2;
  cursor: pointer;
}

.sub-content-item .detail {
  color: #565656;
}

.sub-content-item .detail:last-child {
  margin-bottom: 15px;
}

.sub-content_displayname.sub-step-draft,
.sub-content_displayname.sub-step-draft .detail {
  color: #C2C2C2 !important;
  cursor: not-allowed;
  pointer-events: none;
}

.show-more-less {
  padding: 0;
  border: none;
  cursor: pointer;
  color: #002c77;
  background-color: transparent;
}
