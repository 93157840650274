body {
  margin: 0;
  font-family: NotoSansRegular;
  font-size: 0.875rem;
  /* font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif; */

  /* font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */

/* @font-face {
  font-family: "MMC Display";
  src: local("MMC Display"),
    url("./Assets/Fonts/MMC Display/MMCDisplay_Rg.ttf") format("truetype");
  font-weight: bold;
} */

@font-face {
  font-family: NotoSansLight;
  src: url(./Assets/Fonts/Noto\ Sans/NotoSans-Light.ttf);
}

@font-face {
  font-family: NotoSansRegular;
  src: url(./Assets/Fonts/Noto\ Sans/NotoSans-Regular.ttf);
}

@font-face {
  font-family: NotoSansBold;
  src: url(./Assets/Fonts/Noto\ Sans/NotoSans-Bold.ttf);
}

@font-face {
  font-family: NotoSansMedium;
  src: url(./Assets/Fonts/Noto\ Sans/NotoSans-Medium.ttf);
}

@font-face {
  font-family: NotoSansSemiBold;
  src: url(./Assets/Fonts/Noto\ Sans/NotoSans-SemiBold.ttf);
}

@font-face {
  font-family: NotoSansItalic;
  src: url(./Assets/Fonts/Noto\ Sans/NotoSans-Italic.ttf);
}

@font-face {
  font-family: MMCDisplayBold;
  src: url(./Assets/Fonts/MMC\ Display/MMCDisplay_Bd.ttf);
}

@font-face {
  font-family: MMCDisplayRegular;
  src: url(./Assets/Fonts/MMC\ Display/MMCDisplay_Rg.ttf);
}

/* @font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans"),
    url("./Assets/Fonts/Noto\ Sans/NotoSans-Black.ttf") format("truetype");
  font-weight: bold;
} */

/* @font-face {
  font-family: "Noto Sans Light";
  src: local("Noto Sans"),
    url("./Assets/Fonts/Noto Sans/NotoSans-Light.ttf") format("truetype");
  font-weight: light;
} */
