.sticky-note {
    float: left;
    font-family: NotoSansRegular;
    font-size: 0.75rem;
    text-align: left;
    color: #202020;
    border: solid 1px #ebebeb;
    border-top: none;
    border-radius: 0.25rem;
    background-color: #F0FAFF;
    border: 1px solid #9FE0FF;
    padding: 10px;
}

.sticky-note img {
    margin-left: 0.65rem;
    margin-top: 0.25rem;
}

.stickyNoteHeader {
    color: #002c77;
    font-family: NotoSansSemiBold;
    font-size: 0.875rem;
}