.pie-chart svg {
    width: 100%;
    height: 100%;
    margin: auto;
}

.pie-chart svg path.slice {
    stroke-width: 2px;
}

.pie-chart svg polyline {
    opacity: .3;
    stroke: black;
    stroke-width: 2px;
    fill: none;
}