.label-header {
  font-family: Roboto;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.03;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
}
.label-content {
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  padding-top: 1rem;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.qleModalDialog .label-content{
  line-height: 26px;
}

.logoutModalDialog .logoutContainer .label-content{
  text-align: center;
  padding:1rem;
}

.invoices-card-border {
  border-radius: 5px;
  background-color: #20305f;
}


.enrollment-number{
  line-height: 50px;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}
.contract-number {
font-family: Roboto;
font-size: 24px;
font-weight: 300;
font-stretch: normal;
font-style: normal;
line-height: 0.9;
letter-spacing: -0.96px;
text-align: left;
color: #333333;
}
.Invoice-Number {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}


.disbursement-content {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}
.petLabel{
  padding-top: 0rem;
  padding-bottom: 0rem!important;
}
.welcomeModel{
  white-space: pre-line;
  text-align: center;
  margin-left: -15px;
  margin-right: -15px;
}
.welcomeDialog {
    width: 43rem !important;
}
@media only screen and (max-width: 767px) {
.welcomeDialog {
  width: 42rem !important;
  }
}
