.loginView .loginViewButton {
  background-color: #006ec4 !important;
  color: #fff;
}

.loginView input {
  display: block;
  color: #333 !important;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

.loginView .link {
  color: #0077c0;
}

/* .loginView .loginHeader {
  font-weight: bold;
  font-size: 1.5rem;
  color: #1e2e5c;
} */
.loginMediumHeader {
  font-family: 'NotoSansLight';
  color: #001f52;
  font-size: 2rem;
  padding-top: 1.2rem !important;
  /* padding-left: 0rem !important; */
}

.loginHeader {
  /* font-weight: bold; */
  font-size: 2rem !important;
  color: #0065ac;
  font-family: NotoSansRegular;
  padding-top: 0.5rem !important;
  /* padding-bottom: 2rem !important; */
}

.loginView .loginSubheader {
  color: #333;
  font-size: 1rem;
}

h5 {
  color: #0065ac !important;
  font-family: 'NotoSansRegular';
  font-weight: 100 !important;
  font-size: 1.75rem;
}

.tag {
  position: absolute;
  z-index: 1;
  float: left;
  padding: 5px;
  color: #ffffff;
  font-weight: bold;
  background-color: #92ad40;
}

.loginView .loginError {
  color: red;
}

.loginView
  .panel.d-flex.flex-column.align-items-center.justify-content-between.rounded.px-3.py-4.h-100 {
  margin-right: 5rem;
  width: auto;
}
@media only screen and (max-width: 991px) {
  .loginView
    .panel.d-flex.flex-column.align-items-center.justify-content-between.rounded.px-3.py-4.h-100 {
    margin-right: 0rem;
  }
  .loginView .col-lg-7.align-self-end.d-none.d-md-block.py-3 {
    margin-top: -18rem;
  }
}
@media screen and (max-width: 768px) {
  .reset-padding {
    padding: 0;
  }
}



.overlay-content {
  position: absolute;
  bottom: 1rem;
  background-color: #ffffff;
  color: #202020;
  opacity: 0.8;
  right:0.4rem;
}



.bannerBody {
  font-family: NotoSansRegular;
  color: #202020;
  font-size: 0.875rem;
}

.bannerTitle {
  font-family: NotoSansRegular;
  color: #0065ac;
  font-size: 1.5rem;
  font-weight: bold;
}

.additionalInfoFirst{
  font-family: NotoSansRegular;
  color: #767676;
  font-size: 0.9rem;
  font-weight: bold;
}
.additionalInfoSecond{
  font-family: NotoSansRegular;
  color: #767676;
  font-size: 0.9rem;
}
.currentLink{
  font-family: NotoSansRegular;
  color: #002C77;
  font-size: 0.9rem;
}
.logInTitle {
  font-family: 'NotoSansLight';
  color: #001f52;
  font-size: 2rem;
  padding-top: 1.2rem !important;
  /* padding-left: 0rem !important; */
}
.logInTitleDevider {
  border-bottom: 0.125rem solid #96d6f4;
}
.inputLable {
  font-family: 'NotoSansRegular';
  color: black;
  font-size: 0.875rem;
  /* padding-left: 0rem !important; */
}
.inputField {
  font-family: 'NotoSansRegular';
  color: #767676;
  font-size: 0.875rem;
  /* padding-left: 0rem !important; */
}
.inputLable:after{
  padding-left: 0.1rem;
  content: " *";
  color: #A61003;
}
.inputHeight {
  height: 2.5rem
}

@media   (max-width: 576px) {
  .devider {
    border-bottom: 0.125rem solid #96d6f4;
  }
}
/* @media (min-width: 768px) {
    .p-md-5 {
      padding: 0rem!important;
    }
  } */
.logo-fixed-size {
  max-width: 300px;
}

.session-modal-title {
  color: #0065ac !important;
  font-family: 'NotoSansRegular';
  font-weight: 100 !important;
  font-size: 1.75rem;
}