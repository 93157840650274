.panel-side-header {
  /*commented the below line to fix the alignment issue in Enroll Employee */
  /* width: 1037px; */
  font-family: NotoSansMedium;
  /* height: 40px; */
  /* font-family: Noto Sans; */
  font-size: 1.25rem;
  /* font-weight: 500; */
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  text-align: left;
  color: #1e2e5d;
}

.panel-side-header-img {
  /*commented the below line to fix the alignment issue in Enroll Employee */
  /* width: 1037px;  */
  height: 40px;
  font-family: NotoSansBold;
  font-size: 18px;
  /* font-weight: 500; */
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.32; */
  letter-spacing: normal;
  padding-left: 0rem;
  padding-top: 0.2rem;

  text-align: left;
  color: #004280;
}

.mandatory {
  color: red;
}

/* @media (max-width:992px) {
  .panel-side-header-img {
    width: 1037px !important
  }
} */
.form-side-header {
  /*commented the below line to fix the alignment issue in Enroll Employee */
  /* width: 1037px; */
  /* height: 40px; */
  width: 100% !important;
  /* width:1030px; */
  /* padding-bottom: 2.8rem; */
  font-family: NotoSansLight;
  font-size: 32px;
  border-bottom: 0.0625rem solid #96d6f4;
  /* font-weight: 500; */
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  text-align: left;
  color: #1e2e5d;
}

.form-side-header-contact {
  /* height: 40px;
  width: 100% !important; */
  /* width:1030px; */
  /* padding-bottom: 2.8rem; */
  font-family: NotoSansRegular;
  font-size: 0.875rem;
  /* border-bottom: 0.0625rem solid #96D6F4; */
  /* font-weight: 500; */
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  text-align: left;
  color: #202020;
}

.content {
  display: inline-block;
  margin-bottom: 0.5rem;
  cursor: default;
}

.form-side-header-contact span {
  color: #A61003;
}

.form-side-big-header {
  height: 40px;
  font-family: NotoSansLight;
  font-size: 1.45rem;
  /* font-weight: 500; */
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.32; */
  letter-spacing: normal;
  padding-left: 0rem;
  /* padding-top: 0.5rem; */
  padding-bottom: 2.5rem;

  text-align: left;
  /* color: #004280; */
  color: #202020;
}

.form-mandatory {
  /*commented the below line to fix the alignment issue in Enroll Employee */
  /* width: 1037px; */
  height: 40px;
  padding-bottom: 2rem;
  padding-top: 0.325rem;
  font-family: NotoSansRegular;
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  text-align: left;
  color: #f44336;
}

.panel-sid-cont {
  /* margin: 0 0 0.5rem -1rem; */
}
@media only screen and (max-width: 1199px) {
  .panel-sid-img .img-fluid {
    margin-top: 0.25rem;
  }
}
@media only screen and (max-width: 991px) {
  .panel-sid-img .img-fluid {
    max-width: 1.5rem;
    margin-top: 0.25rem;
  }
}
@media only screen and (max-width: 768px) {
  .panel-side-header-img {
    font-size: 12px;
  }

  .panel-sid-cont {
    /* margin: 0 0 0 -0.75rem; */
  }

  .form-side-header {
    width: 1030px;
  }

  .form-row {
    margin-left: 0px;
  }

  .readonly_label_very_big_content {
    font-family: 'NotoSansLight';
    padding: 2rem 2rem 2rem;
    font-size: 1.5rem;
    /* font-weight: 300; */
    /* font-stretch: normal;
  font-style: normal; */
    line-height: 1.8;
    /* letter-spacing: normal; */
    text-align: left;
    color: #202020;
    background: #e0ffe1;
    border: 0.063rem solid #9ad89b;
  }
}

@media only screen and (max-width: 575px) {
  .panel-side-header-img {
    font-size: 18px;
  }
  .panel-sid-img .img-fluid {
    max-width: 1.25rem;
    margin-top: 0.5rem;
  }
  .panel-sid-cont {
    /* margin: 0 0 0 -0.75rem; */
  }
}
