.Button {
    height: fit-content;
    background-color: #ffffff !important;
    color: #002C77 !important;
    border: 1px solid #002C77 !important;
    border-radius: 4px;
    padding: 5px 15px;
    opacity: 1;
    margin-left: 10px;
}

.modalButton{
    height: fit-content;
    background: #002C77 0% 0% no-repeat padding-box !important;
    color: #fff !important;
    border: 1px solid #fff !important;
    border-radius: 4px;
    padding: 5px 15px;
    opacity: 1;
    margin-left: 10px;
}
.modalButton:hover {
    color: #fff !important;
    background: #2C6EF2 0% 0% no-repeat padding-box !important;
    border-radius: 4px;
    opacity: 1;
}
.modalfooter{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
    margin-bottom: 1.7rem;
}
.astentik{
    color: #C53532
}

.descprition1{
    line-height: 0rem;
    margin-top: 1.17rem;
}
.descprition2{
    line-height: 0rem;
    margin-top: 2.17rem;
}
.descprition4{
    margin-top: 1.17rem;
}
.modaltitle{
    font-size: 1.12rem;
    margin-left: 1.12rem;
}

.modalbackground{
    background-color: "#0065AC";
    color: "#FFFFFF";
}

.descprition3{
    line-height: 0rem;
    margin-top: 2.17rem;
    text-align: center;
}
.visuallyhidden{
    display: none;
}