.card-header-image {
  width: auto;
  height: auto;
}

.panel-side-image {
  /* width: auto;
  height: auto; */
  width: 100%;
  height: 100%;
  /* width: 360px;
  height: 260px; */
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.carrierLogoClass {
  max-height: 7rem;
  vertical-align: middle;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 180px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 2px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tippy-text {
  /* opacity: 1; */
  font-size: 10pt;
  font-weight: regular;
  font-family: Noto Sans;
  color: #202020;
  background-color: '#EDF9FC';
}

.carrierLogoClass img {
  max-height: 4rem;
}

.tooltipPosDesktop{
  margin-top: 5rem!important;
}

@media screen and (max-width: 800px){
  .tooltipImg{
    margin-left: 0px;
  }
  .tooltipPos{
    position: absolute;
    min-width: 17rem;
    min-height: 35rem;
    top: -15rem;
    left: -20rem;
    font-size: 1rem;
  }
  .tooltipPosDesktop{
    margin-top: initial!important;
  }
}