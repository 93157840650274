.tableClient{
  background-color:#FAFAFA;
}

.heading{
  font-family: NotoSansLight;
  font-size: 1.5rem;
}

@media(max-width: 414px) {
  button.full-width {
      width: 100%;  
  }
}

.summaryTitle{
  font-family: NotoSansLight;
  padding-bottom: 1rem;
}

.empContentMobile{
  padding-left: 1rem;
}

.empContent{
  padding-left: 1rem;
}

.depTableTemp{
  background-color: #0065AC;
    color: #FFFFFF;
}

.empContent .col-2{
  padding-left: 1rem;
  max-width: 12.66%;
}
.empContent .col-3{
  /* padding-left: 1rem; */
  max-width: 23.5%;
}


.card {
  /* Add shadows to create the "card" effect */
  /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
  transition: 0.3s;
}

/* On mouse-over, add a deeper shadow 
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}*/

/* Add some padding inside the card container */
.emp-container {
  width:282px;
  padding: 4px 16px 30px 16px;
  background-color: #F9F7FB;
}

.call-center .floatingLabel .calendarIcon{
  bottom: 0.75rem !important;
}