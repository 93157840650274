.session_container {
    left: 0px;
    text-align: center;
    display: block;
    width: 100%;
    height: 100%;
    bottom: 0px;
    position: absolute;
}
.pageText {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #333;
    padding: 5px;
    margin: 10px;
}
.contMsg {
    margin-top: 25px;
}