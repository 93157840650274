.cart-container ul {
  margin-top: 5px;
  margin-bottom: 0rem;
  list-style-type: none !important;
}

.cartDropdown {
  padding: 0px 0px;
}

.card-icon {
  margin-top: 4px;
}

.cart-container-landingPage {
  font-size: 0.875em;
  font-weight: 500;
  white-space: nowrap;
  max-width: fit-content;
  padding-left: 16px;
}

.total-label {
  margin-top: 0px;
}

.cart-iconLink {
  cursor: pointer;
}

.dropdown-container {
  width: 282px;
  box-shadow: rgb(212 217 222 / 50%) 0px 0px 0px 0.2rem;
  border: 1px solid #2196F3;
  right: 13px;
  left: auto;
  /* border-top: 0px; */
}

.cart-dropdown-item {
  color: #002C77;
  font-size: 1rem;
}

.cart-dropdown-item {
  padding: 0px 6px;
  text-align: left;
  font: normal normal 600 14px/19px;
  letter-spacing: 0px;
  color: #767676 !important;
  opacity: 1;
  font-size: 0.9rem;
  pointer-events: none;
  background: #F8FAFC 0% 0% no-repeat padding-box;
  opacity: 1;
}

.cart-dropdown-item-total {
  padding: 0px 6px;
  text-align: left;
  font: normal normal 600 14px/19px;
  letter-spacing: 0px;
  color: #767676 !important;
  font-size: 0.9rem;
  pointer-events: none;
  background: #E5EDF4 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: -1px;
}

.cart-accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.75rem 0rem;
  border-bottom: 0.06rem solid #00000033;
  white-space: break-spaces;
}
.cart-total-accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.75rem 0rem;
  white-space: break-spaces;
}

.cart-accordion-image-container {
  display: flex;
}

.cart-accordion-text {
  margin-left: 0.47rem;
  text-align: left;
  font: normal normal 600 14px/19px NotoSansRegular;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.cart-amount-accordion-text {
  margin-left: 0.47rem;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 12px;
  margin-right: 0.47rem;
}

.cart-total-accordion-text {
  margin-left: 0.47rem;
  text-align: left;
  font: normal normal 600 14px/19px NotoSansRegular;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-right: 0.47rem;
}

.total-cost-text {
  text-align: center;
  font: normal normal 600 17px/17px NotoSansRegular;
  letter-spacing: 0px;
  color: #002C77;
  opacity: 1;
  text-decoration: none !important;
  position:relative;
  padding-right: 0.5rem;
}

.cart-dropdown-menu {
  padding: 1px !important;
  margin-top: 11px !important;
}
.cart-container {
  /* margin-left: 63.333333%; */
  position:absolute;
  left:80%;
  margin-left:-120px;
  cursor: pointer;
}
.img-fluid {
  max-width: 145%;
  height: auto;
}
.arrow-img{
  padding-right: 0;
  position:absolute;
  right: -0.75rem;
  top: 0.3rem;
  transform: rotate(180deg);
}
/* .cart-dropdown-right {
  right: 13px;
  left: auto;
} */

@media screen and (min-width: 768px) and (max-width: 992px) {
  .cart-dropdown-menu {
      padding: 1px !important;
      margin-top: 25px !important;
  }
  .cart-container {
    position: absolute;
    left: 70%;
    margin-left: -120px;
    cursor: pointer;
  }
}

@media screen and (max-width: 576px) {
  .card-icon {
    margin-top: 0px;
  }

  .cart-container ul {
    margin-top: 1px;
    margin-bottom: 0rem;
  }

  .total-label {
    margin-top: 0px;
  }

  .leftContainer {
    margin-left: -168px;
  }

  .cart-dropdown-menu {
    padding: 3px !important;
    margin-top: 12px !important;
  }
}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 992px) {

  .total-label {
    margin-top: -2px;
  }

}

.homePagePanels{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.homePagePanels ul{
  width: 100%;
}
.homePagePanels .panel-list,
.homePagePanels .listItemsContainer{
  width: 100%;
}
.homePagePanels .current-benefits-button{
  margin-top: auto!important;
}