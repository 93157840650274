.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-screen {
  min-height: 400px;
}

.split {
  /* height: 100%; */
  width: 50%;
  position: absolute;
  /* z-index: 1; */
  top: 0;
  /* overflow-x: hidden; */
  /* padding-top: 150px; */
}

/* Control the left side */
.left {
  left: 0;
  /* background-color: #111; */
}

/* Control the right side */
.right {
  right: 0;
  /* background-color: red; */
}

.floatingLabel input {
  padding: 0.625rem;
  display: block;
  background-color: transparent;
  border: none;
  height: 2.5rem !important;
  border-style: solid !important;
  border-width: 0.001rem !important;
  border: #dadada;
  border-bottom: #dadada;
}

.border {
  padding-top: 150px;
}

.floatingLabel pb-3+.error-message+.floatingLabel pb-3 {
  border: red;
}

.toast-message {
  width: 5000rem !important;
  border: '1px solid #E6A7A3';
  padding: '16px';
  color: '#AB2F26';
  background: '#FFE8E6';
  /* height:5000rem !important */
}

.addForm {
  padding-top: 0rem !important;
}

img#register-img {
  width: 31.75rem;
  height: 25.3rem;
}

@media (max-width: 629px) {
  img#register-img {
    display: none;
  }
}

@media only screen and (width: 1300px) {
  img#register-img {
    width: 120%;
    height: 50%;
  }
}

@media only screen and (width: 768px) {
  img#register-img {
    width: 21.5rem;
    height: 17rem;
  }
}

@media only screen and (width: 1024px) {
  img#register-img {
    width: 21.5rem;
    height: 17rem;
  }
}

@media only screen and (width: 820px) {
  img#register-img {
    width: 21.5rem;
    height: 17rem;
  }
}

a {
  color: #002c77;
}

@media all and (device-width: 1366px) and (device-height: 1024px) and (orientation: landscape) {
  .ipad-landscape {
    color: blue;
  }

  /* your css rules for ipad landscape */
}

.calendarIcon.retrieveID {
  position: absolute;
  right: 1.4rem;
  top: 3rem;
  color: #002C77
}

.form-mandatory {
  /* color: rgb(244, 67, 54); */
  color: #A61003 !important;
}

.text-input input {
  padding: 0.625rem;
  display: block;
  background-color: transparent;
  border: none;
  height: 2.5rem !important;
  border-style: solid !important;
  border-width: 0.001rem !important;
  border: #DADADA;
  border-bottom: #DADADA;
  color: #202020;
  border-radius: 3px;
}

.text-input label {
  position: relative;
  line-height: 1.2rem;
  font-size: 0.875rem;
  color: #202020;
  font-family: NotoSansMedium;
}

.forMFA input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  border: none;
  color: transparent;
  height: auto;
  position: absolute;
  right: 1.5rem;
  top: 0;
  width: 2rem;
  z-index: 9;
}

.timerClass {
  /* color: rgb(76, 175, 80); */
  color: #14853D;
  font-family: NotoSansBold
}

.retrievePassword {
  min-height: 43vh;
}

.tooltipWrapper {
  position: relative;
}
.tooltipWrapper.register{
  margin-right: 1rem;
}

.strength-bar {
  display: flex;
}

.strength-bar__segment {
  flex: 1;
  height: 0.25rem;
  width: 2rem;
  margin-right: 5px;
  background: #ddd;
  transition: background-color 0.3s;
}

.strengthBarDiv {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.strengthBarDiv .strength-text {
  font-size: 8pt;
  height: 1rem;
  padding-left: 0.25rem;
  margin-left: auto;
}

.strengthBarDiv .strength-bar__segment.active.red {
  background: #C53532
}

.strengthBarDiv .strength-bar__segment.orange.active {
  background: #8D6A04;
}

.strengthBarDiv .strength-bar__segment.blue.active {
  background: #0065AC;
}

.strengthBarDiv .strength-bar__segment.green.active {
  background: #14853D;
}

.strengthBarDiv .strength-text.red {
  color: #767676
}

.strengthBarDiv .strength-text.orange {
  color: #767676;
}

.strengthBarDiv .strength-text.blue {
  color: #767676;
}

.strengthBarDiv .strength-text.green {
  color: #767676;
}

/* .forRegistration + .col-lg-5{
  min-width: 50%!important;
  margin-left: 1rem;
} */
.retrieveUserID {
  display: flex !important;
  align-self: flex-start !important;
  padding-left: 3rem !important;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
  position: relative;
  width: 100%;
  padding-right: 15px
}

.retrieveUserID+.retrieveUserID {
  margin-left: 8.333333%;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  padding-top: .5rem !important;
  flex: 0 0 50%;
  max-width: 50%;
}

.MFAimage {
  width: 31.75rem;
  height: 25.3rem;
}

/* .MFALoginImage {
  width: 100%;
} */

.dividerline {
  border-bottom: 0.0625rem solid #ccc;
  width: 100%;
  margin: 10px 0px;
}

.APIErr {
  /* color: rgb(244, 67, 54); */
  color: #AE1409;
  margin: 0.25rem;
  padding-left: 0.625rem;
}

.custom_confirmation_message p {
  margin-top: 0.5rem;
}

.emailText {
  color: rgb(0, 44, 119);
}

.mfaBtnWrapper {
  padding-top: 5.5rem;
  padding-bottom: 1.5rem;
  justify-content: space-between;
}

.mfaBtn .primaryBackground:not(:disabled) {
  background-color: #002C77;
  border-color: #002C77;
}

.btn-link {
  color: #002C77;
  font-size: 0.875rem;
  font-family: NotoSansMedium;
}

.register .radio-options-wrapper{
  display: flex;
}
.register .radio-options-wrapper > div{
  margin-right: 1rem;
}
.register.inline .radio-options-wrapper > div input{
  margin-right: 0.5rem;
}
.register.radio-button-group .form-mandatory{
  margin-top: 0.25rem;
  margin-left: 0.25rem;
}
