.contact-preference {
    margin: 0 -1.5rem;
    padding: 0 3rem;
}

.contact-preference h4 {
    margin-bottom: 1.5rem;
}

.contact-preference .form-check-inline {
    margin-left: 0;
}

.email-input-wrapper, .phone-input-wrapper {
    margin-top: 1.5rem;
}

.contact-preference .errorClass input {
    border: 1px solid #A61003;
}

.contact-preference .errorClass .error-message {
    color: #A61003;
    font-size: 0.75rem;
    font-family: NotoSansRegular;
}
.contact-preference .contact{
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 0;
}


@media only screen and (max-width: 767px) {
    .contact-preference {
        margin: 0;
        padding: 0 1rem;
    }
}
