.cross-sell-form {
    padding: 0 2rem;
}

.footer-action-buttons {
    display: flex;
    padding: 2rem 1rem;
    justify-content: flex-end;
}

.footer-action-buttons button {
    margin-left: 16px;
}