input:focus, .text-input input:focus {
    outline: none !important;
    border-radius: 2px !important;
    border: 1px solid #96D6F4;
}
.text-input input.inputLabel{
    border: none!important;
    border-style: hidden!important;
    padding: 0;
}
.text-input button, .text-input button:hover{
    background-color: transparent;
    border: none;
}
.text-input input{
    width: 100%;
}