.confirmation_link {
  width: 300px;
  height: 21px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
}
.confirmation_link a {
  color: #1a88e2;
}
.confirmation_message {
  padding-top: 9px;
  padding-right: -3px;

  font-family: NotoSansLight;
  font-size: 1.5rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  margin-left: 24px;
}

/* IPhone SE */
@media only screen and (width: 375px) {
  .confirmation_message{
    text-align: center;
  }
  .custom_confirmation_message{
    text-align: center;
    margin-left:0rem;
  }
  .linker{
    margin-left:0rem;
  }
}
/* IPhone XR */
@media only screen and (width: 414px) {
  .confirmation_message{
    text-align: center;
  }
  .custom_confirmation_message{
    text-align: center;
    margin-left:0rem;
  }
  .linker{
    margin-left:0rem;
  }
}
/* IPhone 12 */
@media only screen and (width: 390px) {
  .confirmation_message{
    text-align: center;
  }
  .custom_confirmation_message{
    text-align: center;
    margin-left:0rem;
  }
  .linker{
    margin-left:0rem;
  }
}
/* PIXEL */
@media only screen and (width: 393px) {
  .confirmation_message{
    text-align: center;
  }
  .custom_confirmation_message{
    text-align: center;
    margin-left:0rem;
  }
  .linker{
    margin-left:0rem;
  }
}
/* Ipad */
@media only screen and (width: 768px) {
  .confirmation_message{
    text-align: center;
  }
  .custom_confirmation_message{
    text-align: center;
    margin-left:0rem;
  }
  .linker{
    margin-left:0rem;
  }
}
/* Ipad Air */
@media only screen and (width: 820px) {
  .confirmation_message{
    text-align: center;
  }
  .custom_confirmation_message{
    text-align: center;
    margin-left:0rem;
  }
  .linker{
    margin-left:0rem;
  }
}
.confirmation-container {
  /* display: flex; */
}

.header-text {
  font-size: 24px;
  font-weight: 300;
}
.title-text {
  font-family: Roboto;
  font-size: 28px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.79;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.searchFormWrapper {
  padding-left: 0;
  padding-right: 0;
}

.stepFormWrapper{
  background-color: #fff;
}
.enrollmentWrapper .formWrapperDiv{
  width: 100%
}