.dropdown {
  position: absolute;
  left: 0;
  min-height: 100%;
  width: 100%;
  margin-top: 0.7rem;
  background-color: #ffffff;
  box-shadow: 0 0.188em 0.375rem 0 rgba(100, 84, 84, 0.16);
  z-index: 120;
}

.dropdownRow {
  display: flex;
  flex-direction: row;
}

.sideDrawerContainer.open .dropdown {
  position: relative;
  background-color: #0c1c48;
  min-height: fit-content;
  box-shadow: revert;
  padding-left: inherit;
  margin-top:1.5rem;
}

.sideDrawerContainer.open .dropdownRow {
  flex-direction: column;
}
