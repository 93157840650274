.select-custom-dropdown select {
    border : 1px solid #DADADA; 
    border-radius: 2px !important;
     outline: none !important;
     width: 100%;
     height: 2.5rem;
     
   
}

.select-custom-dropdown label {
    line-height: 1.2rem;
    font-size: 0.875rem;
    color: #202020;
    font-family: NotoSansMedium;
    display: inline-block;
    margin-bottom: 0.5rem;

}



.select-custom-dropdown .placeholder-option {
    color: #aaa; /* A light grey color for a dim effect */
  }


.select-custom-dropdown select:focus {
    outline: none !important;
    border-radius: 2px !important;
    border: 1px solid #96D6F4;  
    
}




