.step-form {
    width: 100%;
    display: flex;
}

.step-form.forMFA {
    display: initial;
}

.step-form>.form-content-wrapper {
    flex: 3;
    padding: 1rem 1rem 1rem 3rem;
    max-width: 75%;
}

.step-form>.side-nav-wrapper {
    flex: 1;
    padding: 2rem 1rem;
    background-color: #f9f9f9;
    border-left: 1px solid #eaecf1;
}


.step-form>.form-content-wrapper-endorsement {
    flex: 3;
    padding: 1rem 1rem 1rem 3rem;
    max-width: 98%;
}

.btn-outline-primary-contrast {
    color: #002C77 !important;
    border-color: #002C77 !important;
}
.btn-outline-primary-contrast:hover {
    color: #fff !important;
}
.btn-primary-contrast{
    background-color: #002C77  !important;
    border-color: #002C77  !important;
}
.btn-primary-contrast:disabled{
    background-color: #DADADA  !important;
    border-color: #DADADA  !important;
    color: #949494  !important;
}

.btn-primary-contrast a{
    color: #002C77  !important;
    border-color: #002C77  !important;
}