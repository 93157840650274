.floatingLabel {
  position: relative;
  line-height: 1.2rem;
  font-size: 0.875rem;
  color: #202020;
  font-family: NotoSansMedium;
  margin-bottom: 0%;
  padding-top: 0rem;
}


.inputBorder{
  border: azure !important;
  border-width:0px !important;
}

/* .labelClass {
  display:flex; 
  flex-direction: row;
  white-space: nowrap; 

} */
/* .floatingLabel .required {
  border-bottom: 0.0625rem solid #f9b44d !important;
} */

.floatingLabel input {
  padding: 0.625rem;
  display: block;
  background-color: transparent;
  /* border: none; */
  border-radius: 3px;
  border-bottom:#DADADA;
  border: #DADADA;
}

/* .floatingLabel input:focus {
  outline: none;
  box-shadow: 0 0 1rem #ddd;
} */

.floatingLabel input[type='date']::-webkit-calendar-picker-indicator {
  color: #002C77;
  opacity: 0;
  padding-left: 5rem !important;
  height: 1rem
}

.floatingLabel pb-3 > .error-message
{
color:green
  }


/* input[type="date"]:in-range::-webkit-datetime-edit-text {
  padding-left: 0.625rem;
  color:#767676
} */

.floatingLabel input:focus {
  outline: none !important;
  border: 1px solid #96D6F4;
  /* box-shadow: 0 0 10px #719ECE; */
}


.datepicker::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #767676;
}

.floatingLabel input[type='date']::-webkit-input[type="date"]::placeholder{
  text-align: center;
}

.floatingLabel::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #00C851;
}

.floatingLabel input[type='date']::-webkit-calendar-picker-indicator {
  padding-left: 0.625rem;
  font-family: NotoSansRegular;
  color: #767676
}

/* .floatingLabel input[type='date']::-webkit-input[type='date'] ::placeholder {
  padding-left: 0.625rem;

} */

.pb-3 {
  padding-bottom: 0rem !important;
}

/* .floatingLabel .calendarIcon {
  float: right;
  position: relative;
  right: 0.25rem;
  left: 10rem;
  top: 3.5rem;
  color: #002C77;
  pointer-events: none;
} */

.floatingLabel .calendarIcon {
  
  float: right;
  position: absolute;
  right: 0.75rem;
  bottom: 0.75rem;
  top: unset !important;
  color: #002C77;
  pointer-events: none;
}

.floatingLabel label {
  font-size: 0.875rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0.2rem;
  top: 1rem;
  transition: 0.2s ease all;
}

.floatingLabel label.no-floating {
  position: relative;
  top: unset;
  left: unset;
}

/* .floatingLabel span {
  color: red;
} */

::-webkit-input-placeholder {
  /* padding-left: 0.625rem; */
  font-family: NotoSansRegular;
  color: #767676
}

/* .floatingLabel .isFocused {
  transform: translateY(-1.5rem) scale(1);
} */

.elementDisabled {
  background-color: #f3f3f3 !important;
}

.errorClass {
  border:red ;
  border-bottom:red
}

/* input[type="text"] {
  width: 0.0625rem !important;
  height: 0.0625rem !important;
} */

input[type="text"] {
  padding: 0.625rem;
 
}

input[type="date"] {
  padding-left: 0.5rem!important;


}

input {
  line-height: 1em !important;
}

input[type="text"] {
  padding-left: 0.5rem !important;

}

input[type="email"] {
  padding-left: 0.5rem !important;

}

input[type="password"] {
  padding-left: 0.5rem !important;

}

input[type="date"]{
color: #767676

}
/* padding for the input field*/
.pb-3{
  padding: 1rem 1rem 1rem 0rem;
}

.input {
  height: 20rem !important; 
}
        /* input {
          border: 2px solid #FF0000;
        } */


        .tileStyle{
          display: inline-block;
    margin-left: 2rem;
    margin-bottom: 0.25rem;
        }

        
        @media only screen and (max-width: 1199px) {
          .tileStyle{
            margin-left: -0.75rem;
              }
        }

@media only screen and (max-width: 991px) {
  .tileStyle{
margin-left: 6.25rem;
  }
}

@media only screen and (max-width: 575px) {
  .tileStyle{
margin-left: 4rem;
  }

 
  
}

