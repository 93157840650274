.table tbody tr {
  border: 1px solid #dee2e6;
}

.custom-accordion{
  margin-bottom: 1rem;
}

.form-check-inline span{
  margin: 0 0.5rem;
  color: #202020;
  font-size: 0.875rem;
  font-family: "NotoSansRegular";
}
.form-check-inline{
  margin-left: 2rem;
}
.custom-accordion .card-body{
  background-color: rgba(0,0,0,.03);
  padding: 0.25rem 2rem 1rem 2.5rem;
}
.custom-accordion .card-body:empty{
  padding: 0;
}
.dispnone{
  display: none;
}
.custom-accordion .card-header{
  border-bottom: none;
}
.custom-accordion .accordionHeader{
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  position: relative;
}
.custom-accordion .accordionHeader img{
  margin-right: 1rem;
}
.custom-accordion .accordion-item:hover{
  background-color: inherit;
  color: initial!important;
  font-weight: 400;
}
.custom-accordion .accordion-item:not(.status){
  color: initial!important;
  width: 80%;
}
.custom-accordion .accordion-item.status{
  display: flex;
  width: 8%;
  align-items: center;
}

.custom-accordion .statusMessage{
  position: absolute;
  right: 2rem;
  top: 0.5rem;
}

.criticalIllness .button-group{
display: flex;
flex-direction: row;
justify-content: flex-end;
margin-top: 2rem;
}
.policyReplacementQ{
border-top: 1px solid #dee2e6;
}
.policyReplacementQ .policy-replacemnt-heading{
  font-size: 1.5rem;
  margin-top: 1.5rem;
} 
.question-title, .policyDetails-heading{
  display: inline-block;
  margin-bottom: 0.5rem;
}
.policyReplacementQ input[type='radio'] + span{
  margin: 0 0.5rem;
  color: #202020;
  font-size: 0.875rem;
  font-family: "NotoSansRegular";
}
.policyReplacementQ input[type="text"]{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #DADADA;
  border-radius: 4px;
  opacity: 1;
}
.policyReplacementQ .policyDetails{
  display: inline-flex;
  flex-direction: column;
  margin-right: 1rem;
  margin-bottom: 1rem
}
.policyReplacementQ .radioGroup{
  margin-bottom: 1rem;
}
.criticalIllness .accordion-content-item-title {
font-weight: 600;
display: block;
margin-bottom: 0.5rem;
}
.criticalIllness .accordion-content-item:first-of-type{
margin-bottom: 1rem;
margin-top: 1rem;
}
.criticalIllness .beneficiary-note .sticky-note{
display: block;
background-color: #F9F7FB!important;
border-color: #CCB3E0!important;
}

.criticalIllness .disclosureNote .sticky-note{
display: block;
}

.criticalIllness .beneficiary-note{
display: flex;
flex-direction: column;
margin-top: 2rem;
border-top: 1px solid #dee2e6;
}
.criticalIllness .beneficiary-note .btn{
width: max-content;
margin-bottom: 1rem;
}

.criticalIllness .beneficiary-note .stickyNoteHeader{
margin-left: 1rem;
}
.beneficiary-note h3{
font-size: 1.5rem;
margin: 1rem 0;
margin-top: 2rem;
}
.criticalIllness .atomCoverageTable td:has(.atomCoverageTableRadio){
background: #F4F4F4 0% 0% no-repeat padding-box;
border: 1px solid #DADADA;
border-radius: 0px 0px 4px 4px;
opacity: 1;
}
.criticalIllness .form-check-inline{
margin-left: 0;
}
.criticalIllness .atomCoverageTable .coverageRadio span{
margin-right: 0.5rem;
}
.criticalIllness .radioGroup + .sticky-note{
width: 100%;
}
.summaryWrapper{
display: flex;
flex-direction: column;
}
.summaryWrapper .sticky-note{
display: flex;
align-items: center;
}
.summaryWrapper .sticky-note .mt-1{
margin-top: 0!important;
}
.summaryWrapper .sticky-note img{
margin: 0 1.5rem; 
}
.stepHeader{
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
margin-top: 1.5rem;
}
.coverageOptionTable th:first-of-type{
width: 1%;
}
.criticalIllness .carrierLogoClass img{
  height: 4rem;
}
.criticalIllness .headerLogo{
  align-items: center;
}
.coverageTable th,
.coverageTable td{
  width: auto;
}
.coverageTable .numeric-value{
  text-align: right;
}
@media only screen and (min-width: 1024px){
  .coverageTable .fixedWidth10{
    width: 10rem;
  }
}
@media only screen and (max-width: 780px) {
  .coverageTable th:first-of-type {
    display: inherit!important;
  }
}
.criticalIllness .accordion-content-padding{
  padding-top: 1rem;
}
button.loginContainerButton{
  background: #002C77;
  color: #fff;
}

button.registerContainerButton{
  color: #002C77;
  background: #fff;
}

button.customPrimaryBackground{
  color: #fff;
  background: #002C77;
}

.btn-outline-primary-contrast {
  color: #002C77 !important;
  border-color: #002C77 !important;
}
.btn-outline-primary-contrast:hover {
  color: #fff !important;
}
.btn-primary-contrast{
  background-color: #002C77  !important;
  border-color: #002C77  !important;
  color: #fff !important;
}
.btn-primary-contrast:disabled{
  background-color: #DADADA  !important;
  border-color: #DADADA  !important;
  color: #949494  !important;
}
.btn-primary:disabled{
  background-color: #DADADA  !important;
  border-color: #DADADA  !important;
  color: #949494  !important;
}

.btn-primary-contrast a{
  color: #002C77  !important;
  border-color: #002C77  !important;
}

button.primaryOutline:not(:disabled):hover, button.primaryBackground:not(:disabled):hover{
  background: #002C77 !important;
  border-color: #002C77 !important;
  color: #fff !important;
}
button.primaryOutline[disabled] {
  background: #002C77 !important;
  color: #fff;
}
.insured-smoker-radio {
  min-width: 150px;
  text-align: right;
}

.lable-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

