.floatingLabel {
    position: relative;
    font-size: 0.875rem;
    color: #202020;
    font-family: NotoSansMedium;
    line-height: 2rem;
    padding-bottom: 0rem;
    margin-bottom: 0%;
}

.floatingLabel span {
    color: #A61003;
}

input {
    line-height: 1em !important;
}

/* .floatingLabel .required {
    border-bottom: 0.0625rem solid #f9b44d !important;
} */

/* .floatingLabel .required {
    border-bottom: 0.0625rem solid ;
} */

.floatingLabel input {
    padding: 0.625rem;
    display: block;
    background-color: transparent;
    /* border: none; */
    border-bottom:#DADADA;
    border: #DADADA;
    /* border-bottom: 0.0625rem solid rgb(214, 51, 51); */
     height:50px !important;
 
}

/* .floatingLabel input:focus {
    outline: none;
    box-shadow: 0 0 1rem #ddd;
} */

 .floatingLabel input[type='date']::-webkit-calendar-picker-indicator {
    color: #002C77;
    opacity: 0;
    height: 1rem;
    padding-left: 5rem;
}

.pb-3 {
    padding-bottom: 0rem !important;
}

.floatingLabel .calendarIcon {
    float: right;
    position: absolute;
    right: 1.5rem;
    bottom: 1rem;
    color: #002C77;
    pointer-events: none;
} 
.specialCalendarIcon {
    float: right;
    position: absolute;
    right: 1.5rem;
    top: 2.5rem;
    color: #002C77;
    pointer-events: none;
} 

/* .floatingLabel .calendarIcon {
    float: right;
    position: absolute;
    right: 0.25rem;
    left: 10rem;
    top: 3.5rem;
    color: #002C77;
    pointer-events: none;
} */

/* input[type="text"] {
    width: 18.25rem;
    height: 2.5rem;
} */

/* .floatingLabel .calendarIcon {
    float: right;
    position: absolute;
    right: 0.75rem;
    top: 1rem;
    color: #1a88e2;
    pointer-events: none;
} */



/* .floatingLabel label {
    font-size: 0.875rem;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0.2rem;
    top: 2rem;
    transition: 0.2s ease all;
} */

.floatingLabel .isFocused {
    transform: translateY(-1.5rem) scale(1);
    border: #DADADA;
}

.elementDisabled {
    background-color: #f3f3f3 !important;
}

.input {
    height: "200px"
}
        .input {
            border: 2px solid #FF0000;
        }