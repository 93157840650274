.loginView .loginViewButton {
  background-color: #006ec4 !important;
  color: #fff;
}

.loginView input {
  display: block;
  color: #333 !important;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

.loginView .link {
  color: #0077c0;
}

/* .loginView .loginHeader {
  font-weight: bold;
  font-size: 1.5rem;
  color: #1e2e5c;
} */
.loginMediumHeader {
  font-family: 'NotoSansLight';
  color: #001F52;
  font-size: 2rem;
  border-bottom: 1px solid red;
  border-bottom: 0.125rem solid #96D6F4;
  padding-top: 1.2rem !important; 
  /* padding-left: 0rem !important; */
}

.loginView .loginHeader {
  font-weight: bold;
  font-size: 2rem !important;
  color: #0065AC;
    /* padding-bottom: 2rem !important; */

}


.loginView .loginSubheader {
  color: #333;
  font-size: 1rem;

}

h5{
  color:#0065AC !important;
  font-family: 'NotoSansRegular';
  font-weight: 100 !important;
  font-size:1.75rem
}



.loginView .loginError {
  color: red;
}

.loginView .panel.d-flex.flex-column.align-items-center.justify-content-between.rounded.px-3.py-4.h-100{
  margin-right: 5rem;
  width: auto;
}
@media only screen and (max-width: 991px) {
  .loginView .panel.d-flex.flex-column.align-items-center.justify-content-between.rounded.px-3.py-4.h-100{
    margin-right: 0rem;
  }
  .loginView .col-lg-7.align-self-end.d-none.d-md-block.py-3{
    margin-top: -18rem;
  }
}
 @media screen and (max-width: 768px) {
   .reset-padding {
     padding: 0;
   }
  }

    /* @media (min-width: 768px) {
    .p-md-5 {
      padding: 0rem!important;
    }
  } */