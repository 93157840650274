.statusBadge {
  font-size: 0.6875rem;
  color: #d51b61;
  background-color: #fff;
  text-align: center;
  border: 1px solid #d51b61;
  border-radius: 0.25rem;
  text-transform: capitalize;
  height: 1.6rem;
}

.sticky-note {
  float: left;
  font-family: NotoSansRegular;
  font-size: 0.75rem;
  text-align: left;
  color: #202020;
  border: solid 1px #ebebeb;
  border-top: none;
  border-radius: 0.25rem;
  background-color: #F0FAFF;
  border: 1px solid #9FE0FF;
  /* color: #666666; */
  padding: 10px;
}
.sticky-note-regular {
  float: left;
  font-family: NotoSansRegular;
  font-size: 0.75rem;
  text-align: left;
  color: #202020;
  border: solid 1px #ebebeb;
  border-top: none;
  border-radius: 0.25rem;
  background-color: #F0FAFF;
  border: 1px solid #9FE0FF;
  /* color: #666666; */
  padding: 10px;
}
.sticky-note-medium {
  float: left;
  font-family: NotoSansMedium;
  font-size: 0.75rem;
  text-align: left;
  color: #202020;
  border: solid 1px #ebebeb;
  border-top: none;
  border-radius: 0.25rem;
  background-color: #F0FAFF;
  border: 1px solid #9FE0FF;
  /* color: #666666; */
  padding: 10px;
}
.stickyNoteHeader{
  color:#002c77;
  font-family: NotoSansSemiBold;
  font-size: 0.875rem;
}
.second-title{
  color:#0065AC;
  font-size: 0.875rem;
  font-family: NotoSansBold;
}
.first-title{
  color:#001F52;
  font-size: 0.875rem;
  font-family: NotoSansRegular;
}

.title{
  color:#001F52;
  font-size: 0.875rem;
  font-family: NotoSansSemiBold;
}