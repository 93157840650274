
.iconLink {
  cursor: pointer;
}

.container {
  width: 282px;
  box-shadow: rgb(0 123 255 / 50%) 0px 0px 0px 0.2rem;
}

.header_user_icon{
  color : #ededed
}