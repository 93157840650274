.split {
    /* height: 100%; */
     width: 50%;
    position: absolute;
    /* z-index: 1; */
    top: 0;
    /* overflow-x: hidden; */
    /* padding-top: 150px; */
}

/* Control the left side */
.left {
    left: 0;
    /* background-color: #111; */
}

/* Control the right side */
.right {
    right: 0;
    /* background-color: red; */
}

/* .floatingLabel .required {
    border-bottom: 0.0625rem solid !important;
} */


.floatingLabel input {
    padding: 0.625rem;
    display: block;
    background-color: transparent;
    border: none;
    height: 2.5rem !important;
    border-style: solid !important;
    border-width: 0.001rem !important;
    border: #DADADA;
    border-bottom: #DADADA;
}

.border {
    padding-top: 150px
}

.floatingLabel pb-3 + .error-message +.floatingLabel pb-3{
   border: red
}

.toast-message {
   width:5000rem !important;
    border: '1px solid #E6A7A3';
            padding: '16px';
            color: '#AB2F26';
            background: '#FFE8E6';
   /* height:5000rem !important */
}

.addForm {
    padding-top: 0rem !important
}

/* .form-row {
    margin-left: 50px !important;
    padding-top: 10px
} */

/* @media (max-width:629px) {
    img#register-image {
        display: none;
    }

} */

/* @media (max-width:992px) {
    img#register-image {
        width: 120%;
        height:50%
    }
  

         
} */
img#register-img  {
    width: 31.75rem;
    height: 25.3rem;
  }

@media (max-width: 629px) {
    img#register-img {
      display: none;
    }

  }
  @media only screen and (width: 1300px) {
  
    img#register-img  {
      width: 120%;
      height: 50%;
    }
  
   
  }
  @media only screen and (width: 768px) {
    img#register-img  {
      width: 21.5rem;
      height: 17rem;
    }
  
    
  }
  @media only screen and (width: 1024px) {
    img#register-img  {
      width: 21.5rem;
      height: 17rem;
    }
  }
  @media only screen and (width: 820px) {
    img#register-img  {
      width: 21.5rem;
      height: 17rem;
    }
  }
/* @media (max-width: 992px) {
    #dualcolumn {
        .className ="col-md-12"
    }
} */

/* If you want the content centered horizontally and vertically */
/* .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
} */

/* Style the image inside the centered container, if needed */
/* .centered img {
    width: 150px;
    border-radius: 50%;
} */


/* @media (min-width: 768px) {
    .pr-md-5,
.px-md-5 
{
padding-right: 0rem !important;
}
}
     */

         /* @media (min-width: 768px) {
        .pr-md-5,
    .px-md-5 
    {
    padding-left: 0rem !important;
    }
    } */
        

        a {
            color: #002C77 ;
        }
    @media all and (device-width: 1366px) and (device-height: 1024px) and (orientation:landscape) {
            .ipad-landscape { color: blue; } /* your css rules for ipad landscape */
    }