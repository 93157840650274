.ro .label-header {
  font-family: NotoSansRegular;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.03;
  letter-spacing: normal;
  text-align: left;
  color: #202020;
}
.label-content {
  font-family: NotoSansRegular;
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: normal;
  text-align: left;
  color: #202020;
}

.ro-label-content {
  font-family: NotoSansRegular;
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: normal;
  text-align: left;
  color: #202020;
}
.list-style {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
}

.notification_content {
  font-family: Roboto;
  font-size: 0.8rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.notification_label {
  font-family: Roboto;
  font-size: 0.8rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
}

.readonly_label_big_content {
  font-family: Roboto;
  font-size: 2rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.8;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.readonly_label_medium_content {
  /* font-family: Roboto; */
  padding: 0.0625rem 0.0625rem 0.0625rem;
  font-size: 0.8rem;
  /* border: 0.063rem solid #9FE0FF; */

  /* font-weight: 300; */
  /* font-stretch: normal;
  font-style: normal; */
  line-height: 1rem;
  /* letter-spacing: normal; */
  text-align: left;
  color: #202020;
  background: #edf9fc;
}

.read-only-image {
  width: auto;
  height: auto;

  text-align: center;
  padding-bottom: 3rem;
  padding-top: 3rem;
  padding-left: 1rem;
}

.readonly_label_header {
  position: relative;
  font-size: 1rem;
  color: #202020;
  font-family: NotoSansMedium;
  line-height: 2rem;
  padding-bottom: 0rem;
  margin-bottom: 0%;
}
.readonly_label_very_big_content {
  /* font-family: Roboto; */
  font-family: NotoSansLight;
  padding: 2rem 2rem 2rem;
  font-size: 1.5rem;
  /* font-weight: 300; */
  /* font-stretch: normal;
  font-style: normal; */
  line-height: 1.8;
  /* letter-spacing: normal; */
  text-align: left;
  color: #202020;
  background: #e0ffe1 0% 0% no-repeat padding-box;
  border: 0.063rem solid #9ad89b;
  border-radius: 4px;
}

.readonly_label_ms_content {
  font-family: NotoSansRegular;
  padding: 2rem 2rem 2rem;
  font-size: 0.75rem;
  /* font-weight: 300; */
  /* font-stretch: normal;
    font-style: normal; */
  line-height: 1rem;
  /* letter-spacing: normal; */
  text-align: left;
  color: #202020;
  border: 0.063rem solid #e5dcaa;
  background: #fff8d3 0% 0% no-repeat padding-box;
  border-radius: 4px;
}

.readonly_label_bigger_content {
  font-family: Roboto;
  font-size: 3rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.8;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.url {
  font-family: NotoSansBold;
  color: #002c77 !important;
  font-size: 0.875rem !important;
  font-weight: 200;
  font-style: bold;
  text-align: center;
  padding-left: 8.5rem;
  padding-right: 3rem;
}

.urlLink {
  font-size: 1.125rem;
}

@media only screen and (max-width: 991px) {
  .readonly_label_ms_content,
  .readonly_label_very_big_content {
    margin: 1rem 0;
  }

  .readonly_label_medium_content {
    margin: 0.75rem 0.75rem 0.75rem 0rem;
  }

  .urlLink {
    text-align: center;
  }
}

@media only screen and (max-width: 575px) {
  .readonly_label_ms_content,
  .readonly_label_very_big_content {
    margin: 1rem 0;
  }

  .readonly_label_medium_content {
    margin: 1rem 1rem 1rem 0;
  }
}

.clickImageToggle {
  max-width: 1rem;

  width: auto;

  height: auto;

  max-height: 1rem;

  cursor: pointer;
}

.ro-label-header {
  float: left;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #333333;
  padding-bottom: 10px;
}

.ro-label-value {
  float: left;
  font-family: NotoSansRegular;
  font-size: 14px;
  text-align: left;
  color: #202020;
  padding: 10px;
}
/* .panelborder {
  border-radius: 5px;
  background-color: #f8fafc;
  /* border: solid 1px #d1e0ec; */
/* border-top: solid 1px #d1e0ec; */
/* border-bottom: solid 1px #d1e0ec; */
/* border-right: solid 1px #d1e0ec; */
/* }  */

.addedLabelColor {
  color: #00AC41 !important;
}
