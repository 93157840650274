.status-style {
    font-family: Roboto;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    margin-top: -2px;
  
    font-size: '12px';
    color: '#00a190';
    border-radius: '4px';
    border: 'solid 1px #00a190';
  }
  .error-welcome {
    background-color: rgba(0,0,0,0.4);
  }

  .error-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)!important;
  }

  /*Tests*/
.error-welcome .modal-dialog{
    max-width: 26rem !important;
}
.error-welcome .modal-header{
    background-color: #0065AC;
    padding: 0.5rem 1.5rem;
}
.error-welcome .modal-title{
    color: white!important;
    font-size: 18px;
    line-height: 2;
    font-weight: normal;
}

.error-welcome {
    overflow-x: hidden;
    overflow-y: auto;
}
.error-welcome .close{
    font-size: 2.5rem;
    font-weight: 100;
    text-shadow: none;
    opacity: .9;
    color: white;
}
.error-welcome .floatingLabel span {
    color: red;
}

.error-welcome .beneficiary.form-mandatory{
    margin-top: 1rem;
}
#addDependent .selectContainer, .error-welcome .selectContainer
{
    padding-top: 0;
}
#addDependent .selectContainer .selectText, .error-welcome .selectContainer .selectText
{
    height: 2.5rem;
}
#addDependent .selectContainer label, .error-welcome .selectContainer label
{
    margin-bottom: 0.3rem;
}
#addDependent .isRequired, .error-welcome .isRequired
{
    border-color: inherit;
}
#addDependent .floatingLabel  .calendarIcon{
    top: 3.45rem;
}

.error-close {
    opacity: 1;
}
