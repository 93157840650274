.header {
    top: 89px;
    left: 0px;
    height: 91px;
    background: #F0FAFF 0% 0% no-repeat padding-box;
    opacity: 1;
    font: normal normal normal 24px/33px MMC Display;
    letter-spacing: 0px;
    color: #202020;
}

.card {
    /* top: 1378px;
    left: 48px; */
    background: #FBFBFB 0% 0% no-repeat padding-box;
    border: 1px solid #DADADA;
    opacity: 1;
}

.title {
    top: 1394px;
    left: 68px;
    width: 100%;
    text-align: left;
    font-family: NotoSansRegular;
    font-size: 0.975rem;
    letter-spacing: 0px;
    color: #3B3B3B;
    opacity: 1;
    font-weight: bold;
    padding-top: 10px;
    padding-left: 10px;
}

/* .expanded {
    background-color: #F0FDFF;
  } */

.table {
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0px solid #DADADA;
    border-radius: 4px 4px 0px 0px;
    opacity: 1;
    padding-top: 10px;
    padding: 15px;
}

.collapses {
    top: 437px;
    left: 28px;
    opacity: 1;
    padding-left: 0px;
    padding-top: 2px;
    margin: 5px;
}

.collapsesTitle {
    top: 1469px;
    left: 98px;
    text-align: left;
    font: normal normal bold 16px/22px Noto Sans;
    letter-spacing: 0px;
    color: #565656;
    opacity: 1;
    font-weight: bold;
}

.collapsPanal {
    padding: 8px !important;
    margin: 10px;
}

.note {
    font-family: NotoSansRegular;
    font-size: 0.875rem;
}


.collapsPanalHeader {
    display: flex;
}

.toggleCollaps {
    filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);
    width: fit-content;
}

.collapsPanalContent {
    margin: 20px 0px 5px 0px;
}

.collapsPanalHeaderIcon {
    float: right;
    z-index: 1;
}

.tableHeaderStyle {
    color: #FFFFFF;
    background: #0065AC 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 10px;
}

.tableRowStyle {
    padding: 5px;
    opacity: 1;
}

.tableRowCell {
    padding: 10px;
    margin: 10px;
    text-align: left;
}

.cardHeader {
    padding: 5px;
    padding-right: 16px;
    display: flex;
}

.totalCostCardHeader {
    padding: 5px;
    padding-right: 16px;
    display: flex;
}

.Button {
    height: fit-content;
    background-color: #ffffff !important;
    color: #002C77 !important;
    border: 1px solid #002C77 !important;
    border-radius: 4px;
    padding: 5px 15px;
    opacity: 1;
}

.ButtonWaived {
    /* height: 1rem; */
    /* background-color: #f1eaea !important; */
    /* color: #000000 !important; */
    border: 0.01rem solid #DADADA !important;
    border-radius: 4px !important;
    padding: 2px 5px;
    text-align: center;
    background-color: #EBEBEB !important;
    opacity: 1;
    font-size: 0.8rem;
}

.Button:hover {
    color: #fff !important;
    background: #2C6EF2 0% 0% no-repeat padding-box !important;
    border-radius: 4px;
    opacity: 1;
}

.btn:focus {
    outline: none;
    box-shadow: none;
}

.totalCostLabel {
    top: 1394px;
    left: 68px;
    width: 92%;
    text-align: right;
    font-family: NotoSansRegular;
    font-size: 1.375rem;
    letter-spacing: 0px;
    color: #002C77;
    opacity: 1;
    font-weight: normal;
    padding-top: 7px;
    padding-left: 70px;
}

.totalCostBtnAlign {
    top: 1394px;
    left: 38px;
    width: 97%;
    text-align: right;
    margin: 10px;
}

.totalCost {
    display: flex;
    top: 1394px;
    left: 38px;
    width: 8%;
    text-align: left;
    font-family: NotoSansRegular;
    font-size: 1.200rem;
    letter-spacing: 0px;
    color: #002C77;
    opacity: 1;
    font-weight: bold;
    padding-top: 10px;
    padding-left: 5px;
}

.ButtonAnnual {
    height: fit-content;
    background-color: #ffffff !important;
    color: #002C77 !important;
    border: 1px solid #002C77 !important;
    border-radius: 4px;
    padding: 5px 15px;
    opacity: 1;
}

.ButtonSelected {
    text-align: right;
    height: fit-content;
    background: #002C77 0% 0% no-repeat padding-box !important;
    color: #fff !important;
    border: 1px solid #fff !important;
    border-radius: 4px;
    padding: 5px 15px;
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 0px;
}

.ButtonAnnual:hover,

.ConfirmButton:hover {
    color: #fff !important;
    background: #2C6EF2 0% 0% no-repeat padding-box !important;
    border-radius: 4px;
    opacity: 1;
}

/*Mobile View*/
@media only screen and (max-width: 960px) {
    .totalCost {
        display: flex;
        top: 1394px;
        left: 38px;
        width: 8%;
        text-align: center;
        font-family: NotoSansRegular;
        font-size: 1.200rem;
        letter-spacing: 0px;
        color: #002C77;
        opacity: 1;
        font-weight: bold;
        padding-top: 10px;
        padding-left: 5px;
    }

    .totalCostLabel {
        top: 1394px;
        left: 68px;
        width: 82%;
        text-align: right;
        font-family: NotoSansRegular;
        font-size: 1.375rem;
        letter-spacing: 0px;
        color: #002C77;
        opacity: 1;
        font-weight: normal;
        padding-top: 7px;
        padding-left: 70px;
    }

}

.fontStyle {
    font-weight: bold;
}
.styles_collapses__QV6IM .accordion-text-no-image {
    font-weight: bold !important;
}
