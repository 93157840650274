


.custom-checkbox-dropdown {
  font-family: NotoSansMedium;
}
.custom-checkbox-dropdown .custom-dropdown-menu {
    max-height: 180px; /* Adjust this value as needed */
    overflow-y: auto;
    overflow-x: auto;
    border: 1px solid #ccc;
    background-color: white;
    position: absolute;
    z-index: 1000;
    width: 90%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  }
  .custom-checkbox-dropdown .dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  .custom-checkbox-dropdown .dropdown-option {
    display: flex;
    align-items: center;
    
    padding-left:10px;
    padding-right:10px;
    padding-top:2px;
    padding-bottom:2px;
    cursor: pointer;
  }
  .custom-checkbox-dropdown .dropdown-option input {
    margin-right: 10px;
  }
  .custom-checkbox-dropdown .dropdown-arrow {
    margin-left: auto;
  }
  
  
  
  
  
  

  
  .dropdown-header {
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dropdown-header:hover {
    background-color: #f0f0f0;
  }
  
  .dropdown-arrow {
    margin-left: 10px;
  }
  
  .custom-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 15px;
    right: 0;
    border: 1px solid #ccc;
    background-color: #fff;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: auto;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    font-size: 13px;
  }
  
  .dropdown-option {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-option:hover {
    background-color: #f0f0f0;
  }
  
  .dropdown-option input {
    margin-right: 10px;
  }
  
  