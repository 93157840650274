

  .confirmation-container {
    display: flex;
  
  }

  .confirmation_message {
    /* padding-top: 9px;
    padding-right: -3px;
   */
    font-family: NotoSansRegular;
    font-size: 1.5rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
    margin-left: 24px;
  }
  .beneficiariestitle {
    top: 1394px;
    left: 68px;
    width: 100%;
    text-align: left;
    font-family: NotoSansRegular;
    font-size: 0.975rem;
    letter-spacing: 0px;
    color: #3B3B3B;
    opacity: 1;
    font-weight: bold;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
}
.card {
  /* top: 1378px;
  left: 48px; */
  background: #FBFBFB 0% 0% no-repeat padding-box;
  border: 1px solid #DADADA;
  opacity: 1;
}
.cardHeader {
  padding: 5px;
  padding-right: 16px;
  /* display: flex; */
}

  .tri-confirmation-container-box {
    font-family: NotoSansLight;
    /* display: flex; */
    background-color: #F3FFF5;
    padding: 1rem 1rem 1rem 1rem;
    border: solid 1px #ADDFB3;
    margin-bottom:2rem;
    margin-top: 2rem;
      
    
  }

  .loginView {
    margin-right: 5rem;
    width: auto;
  }

  .confirmation{
    padding:.875rem
  }

  .content-bold{
    font-family: NotoSansBold;
  }
  