.cross-sell-products {
    padding: 0 4rem;
    margin: 0 -2rem;
    background-color: #f4f4f4;
}

.products-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 1.5rem -1.5rem;
}

.products-wrapper .product {
    margin: 8px;
    padding: 1rem;
    border-radius: 4px;
    flex: 0 0 calc(25% - 16px);
    border: 1px solid #D1E0EC;
    background-color: #ffffff;
}

.products-wrapper .product .checkBoxAndLabel {
    display: flex;
    align-items: flex-start;
}

.products-wrapper .not-interested {
    text-align: center;
    margin-bottom: 1rem;
    padding-bottom: 0;
    flex: 0 0 calc(100% - 16px);
}

.products-wrapper .product > .row {
    margin: 0;
}

.products-wrapper .product .checkboxLabel  {
    color: #000;
    font-weight: bold;
}

.products-wrapper .product .label-content {
    padding-top: 0.75rem;
}

@media only screen and (max-width: 767px) {
    .cross-sell-products {
        margin: 0;
        padding: 0 2rem;
    }
    
    .products-wrapper .product {
        flex: 0 0 calc(100% - 16px);
    }

}
