/* Tabs container */
.tabs-container>ul {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    list-style: none;
    align-items: flex-end;
    border-bottom: 1px solid #000;
}

/* Tab item */
.tabs-container>ul>li {
    cursor: pointer;
    font-size: 1rem;
    padding: 0.5rem 0;
    position: relative;
    margin-right: 1rem;
    border-bottom: 4px solid transparent;
}

/* Tab content */
.tabs-container>ul>li.active {
    border-bottom-color: #000;
}

.tab-content-wrapper {
    padding: 1.5rem 0;
}
