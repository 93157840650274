.sideDrawerContainer {
  position: fixed;
  top: 0;
  right: 0;
  left: auto;
  width: 31rem;
  height: 100%;
  box-shadow: -0.125rem 0.188rem 0.375rem 0 rgba(0, 0, 0, 0.16);
  background-color: #0a1e5a;
  z-index: 9999;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  max-width: 100%;
  overflow: auto;
}

.sideDrawerContainer.leftToRight {
  transform: translateX(-100%);
}

.sidebarHeader {
  background-image: linear-gradient(134deg, #b32e86 5%, #152e7a 91%) !important;
}
.closeIconContainer {
  position: fixed;
  margin-top: 1.2rem;
  z-index: 1;
}
.sideDrawerContainer.open {
  transform: translateX(0);
}

.userProfileContainer {
  margin-left: none;
}

.closeIcon {
  width: 2rem;
  height: 2rem;
}

.settingsIcon {
  width: 2rem;
  height: 2rem;
}
.userName {
  font-family: Roboto;
  font-size: 3rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  color: #ffffff;
  margin-top: 1.5rem;
  text-align: center;
}

.logoutWrapper {
  margin: 1rem;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
}

.manageAccount {
  font-family: Roboto;
  font-size: 1.2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  display: flex;
}

.manageAccount img {
  margin-right: 10px;
}

.profilePicture {
  width: 9.375rem !important;
  height: 9.375rem !important;
  border-radius: 50%;
  margin: 2rem;
}

.imageWrapper {
  justify-content: unset !important;
}

.sidebarFooter {
  padding-left: 3.5rem !important;
  margin-top: 2rem;
}

.messageCenter {
  width: 1.5rem;
  height: 1.325rem;
}

.message {
  font-size: 0.8rem;
  color: #ffffff;
  padding: 0 1rem 1rem 1rem;
}

@media (min-width: 768px) {
  .userProfileContainer {
    margin-top: 2rem;
  }
  .closeIcon {
    width: 1.144rem;
    height: 1.113rem;
  }

  .profilePicture {
    width: 4.635rem !important;
    height: 4.625rem !important;
    padding: unset;
    margin: 0 2rem 2rem 2rem;
  }

  .manageAccount {
    font-size: 1rem !important;
  }

  .settingsIcon {
    width: 1.6rem;
    height: 1.5rem;
  }

  .userName {
    font-size: 1.875rem !important;
  }

  .userNameContainer {
    align-items: flex-start !important;
  }
}
