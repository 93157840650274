.quicklink-display-area {
    width: 400px;
    height: 165px;
    border-radius: 4px;
    border: 1px solid #D1E0EC;
    background-color: #F8FAFC;
}

.quicklink-heading {
    font-family: NotoSansMedium;
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
    color: #001F52;
    padding: 1.2rem 3rem 1rem 4rem;
}

/* .quicklink-display-area> li {
    cursor: pointer;
    font-weight: 400;
    color: #002C77;
    line-height: 2rem;
    font-size: 0.875rem;
    list-style-type: disc;
    font-family: NotoSansRegular;
} */

 .quicklink-display-area>  ul {
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    color: #002C77;
    line-height: 3rem;
    /* padding-left: 2rem; */
    list-style-type: disc;
    font-family: NotoSansRegular;
    margin-left: 1rem;
} 
 

.quicklink-display-area>span>img {
    width: 8%;
    float: left;
    margin-top: 1.1rem;
    margin-left: 1.1rem;
}