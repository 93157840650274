.floatingLabel {
  position: relative;
  font-size: 0.875rem;
  color: #999;
  font-family: NotoSansMedium;
}

	
.disclosure{
  display: flex;
  white-space: initial;
  /*uncommented for collapse panel list */
  padding-bottom: 0.75rem;
  line-height: 1.2rem;
  font-family: NotoSansRegular ;
  color:black!important;
}
.disclosure-link{
  /* display: flex; */
  white-space: initial;
  /*uncommented for collapse panel list */
  padding-bottom: 0.75rem;
  line-height: 1.2rem;
  font-family: NotoSansRegular ;
}
.disclosure-title-section{
  /* display: flex; */
  white-space: initial;
  /*uncommented for collapse panel list */
  padding-bottom: 0.75rem;
  line-height: 1.2rem;
  font-family: NotoSansRegular ;
}

.disclosure-title-main{
  white-space: initial;
  /*uncommented for collapse panel list */
  padding-bottom: 0.75rem;
  line-height: 1.2rem;
  font-family: NotoSansSemiBold ;
  font-size: 1.25rem !important;

}
.ul-class{
  padding-right:0rem !important ;

  white-space: initial;
  /*uncommented for collapse panel list */
  padding-bottom: 0.75rem;
  font-family: NotoSansRegular;
  font-size: 0.875rem;
}


/* .floatingLabel .required {
  border-bottom: 0.0625rem solid #f9b44d !important;
} */
/* 
.floatingLabel .required {
  border-bottom: 0.0625rem solid;
} */

.floatingLabel textarea {
  padding: 0.625rem;
  display: block;
  background-color: transparent;
  border: none;
  border-bottom: 0.0625rem solid #ccc;
}

/* .floatingLabel textarea:focus {
  outline: none;
  box-shadow: 0 0 1rem #ddd;
} */


.floatingLabel label {
  font-size: 0.875rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0.2rem;
  top: 0.6rem;
  transition: 0.2s ease all;
}

.floatingLabel .isFocused {
  transform: translateY(-1.5rem) scale(1);
}

.elementDisabled {
  background-color: #f3f3f3 !important;
}

/* .displayContent{
  white-space: pre-line;
  background-color: #999;

} */

textarea {
  /* color: red; */
  /* background-color: blue; */
  /* background-color:  #F4F4F4 !important; */
  border: 1px solid #DADADA !important;
  padding: 1rem !important;
  overflow-y: none !important;
  overflow-x: none !important;
    }
  textarea::placeholder{
    padding:0;
  }
/* 
    #section1{
      overflow-y: auto !important;
      overflow-x: auto !important;
      background-color:  #F4F4F4 !important;

    } */

    .divScroll {
      overflow:scroll;
      height:400px;
      width:1000px;
      border: 1px solid #DADADA !important;
      padding: 2rem !important;
      background-color:  #F4F4F4 !important;
      }

      .nodivScroll{
        /* overflow:scroll; */
        height:170px;
        width:1000px;
        border: 1px solid #DADADA !important;
        padding: 1rem !important;
        background-color:  #F4F4F4 !important;

      }

      .sectionClass {
        padding: 1rem !important;
      }

      p{
        line-height: 1.2rem;
      }

      .contentClass{
        font-family: NotoSansRegular;
        font-size: 0.875rem;
        margin-top: 1.2rem;
        /* color:black; */
      }
      @media screen and (max-width: 820px) {
        .disclousreCoverage{
          height: 380px !important;
        }
        .disclousreHospital {
          height: 381px !important;
        }
        .disclousreIdTheft{
          height: 141px !important; 
        }
        .disclousreTravel{
          height: 391px !important;
        }
        .disclousreCriticalIllness{
          height: 352px !important;

        }
        .disclousrePetAssureNation{
          height: 100px !important;

        }
      }
      @media screen and (max-width: 520px) {
        .disclousreCoverage{
          height: 380px !important;
          width: 380px !important;
        }
        .disclousreHospital {
          height: 381px !important;
        }
        .disclousreIdTheft{
          height: 160px !important; 
        }
        .disclousreTravel{
          height: 380px !important;
        }
        .disclousreCriticalIllness{
          height: 380px !important;

        }
        .disclousrePetAssureNation{
          height: 100px !important;

        }
        .disclousreSupplementalHealthInsurance{
          height: 100px !important;

        }
      }
      .floatingLabel.elementsDivider{
        display: block;
        min-width: 20rem;
        background-color: #F8FAFC;
      }
      @media only screen and (min-width: 400px) and (max-width: 1023px) {
        .floatingLabel.elementsDivider{
          margin-left:1rem;
          width:150%;
        }
        
      }
/* 
      ::-webkit-scrollbar {
        width: 1px !important;
      }


      .scrollbar {
        overflow-x: auto;
      }
      
      .scrollbar::-webkit-scrollbar {
        height: 10px;
      }
      
      .scrollbar::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #b90d0d !important;
      } */
      
      