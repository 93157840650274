.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.4);
}

.navContainer .backdrop {
  position: absolute;
  top: 4rem;
  height: 100vh;
}
