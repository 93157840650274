.reports-tab-container {
    border-bottom: 1px solid #000000;
    display: flex;
    gap:13px;
}

.reports-tab {
    margin-right: 12px;
    padding: 13px 0px; /* Top and bottom padding only */
    border: none;
    font-weight: bold;

    color: #002C77; /* Default inactive color */
    background-color: transparent;
    text-align: left; /* Align text to the left */
}

.reports-tab:hover {
    background-color: transparent;
    cursor: pointer;
}

.reports-tab.active {
    padding-top:-3px;
    color: #202020; /* Active tab text color */
    border-bottom: 3px solid #202020; /* Active tab indicator color */
    display: inline-block; /* Make the border width only as long as the text */
}





/* Target your specific form section, assuming you add a class to it like 'include-dependents-form' */
.include-dependents-form .radio-button-group .radio-options-wrapper {
    display: flex;
    /* Use flexbox to layout the children inline */
    align-items: center;
    /* Align the items vertically */
}

.include-dependents-form .radio-button-group .radio-options-wrapper div {
    margin-left: 15px;
}


.customRadioGroup .radioOptions {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* Align the radio buttons and labels vertically */
    justify-content: flex-start; 
    /* Align the group to the start of the container */
    gap: 15px;
    /* Adjust the space between each radio button as needed */
}

.customRadioGroup .radioLabel {
    display: flex;
    align-items: center;
    /* This will vertically align the radio button with the text */
    cursor: pointer;
    /* Change the cursor to indicate the label is clickable */
}

/* If you need to adjust the alignment of the radio button and text individually */
.customRadioGroup input[type="radio"] {
    margin-right: 5px;
    /* Space between the radio button and the label text */
}

.custom-control-radio {

    font-size: 0.875rem;
    color: #202020;
    font-family: NotoSansMedium;


}

/* Align all form inputs, selects, and buttons to the same height */
.form-group input[type="date"],
.form-group .select-dropdown,
.form-group .button {
    height: 40px; /* or any other value that matches your design */
    line-height: 40px; /* should be the same as height for vertical centering */
}

/* Ensure consistent margin for all form elements */
.form-group > div {
    margin-bottom: 15px; /* Adjust as needed */
}

/* Additional styles for radio button alignment */
.customRadioGroup {
    margin-bottom: 0; /* Override if there's any default bottom margin */
}




.filter-title {
    font-weight: 100 !important;
    font-size: 24px; /* Adjust the size as needed */
    color: #000; /* Adjust the color as needed, if it needs to be black */

}

.disable-scroll {
    overflow-x: hidden;

}