/* html,
body,
#app,
#app > div {
  height: 100%;
  background-color: #f1f2f4;
} */
html {
  height: 100%;
}
body,
#root {
  height: 100%;
  background-color: #fff;
}
/* .main-wrapper {
  /* min-height: 25rem; */
/* padding-bottom: 3.5rem; Footer height */
/* } */
.main-wrapper {
  /* min-height: calc(100% - 21.5rem); */
  min-height: calc(100% - 38.5%);

  /* padding-bottom: 3.5rem; */
}

/* .App {
  position: relative;
  /* min-height: 93.5vh; */
/* height: calc(100% - 11.5rem); */
/* } */
.App {
  position: relative;
  height: calc(100%);
}
#truste-consent-button, #truste-consent-required, #truste-show-consent {
  background: #0065ac !important;
}

@media screen and (min-width: 768px) {
  .main-wrapper {
    /* min-height: calc(100% - 12.15rem); */
    min-height: calc(100% - 25.5%);
  }
}
@media screen and (min-width: 768px) and (orientation: portrait) {
  .main-wrapper {
    /* min-height: calc(100% - 14.35rem); */
    min-height: calc(100% - 22.5%);
  }
}

@media screen and (min-width: 1200px) {
  .main-wrapper {
    /* min-height: calc(100% - 14.35rem); */
    min-height: calc(100% - 19%);
  }
}

@media screen and (min-height: 1200px) {
  .main-wrapper {
    /* min-height: calc(100% - 14.35rem); */
    min-height: calc(100% - 14.25%);
  }
}
/* @media screen and (min-width: 768px) {
  .App {
    height: calc(100% - 4.25rem);
  }
}
@media screen and (min-width: 768px) and (max-width: 768px) and (max-height: 1024px) and (orientation: portrait) {
  .App {
    height: calc(100% - 6.5rem);
  }
}*/
