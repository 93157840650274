.hr-dashboard>.products,
.hr-dashboard>.enrollment-info,
.hr-dashboard>.quick-links {
  margin: 2rem 3rem;
}

.hr-dashboard>.enrollment-info {
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid #DADADA;
}

.hr-dashboard .section-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1rem;
  font-family: NotoSansSemiBold;
}

.enrollment-info-table tr th {
  font-weight: normal;
  padding: 0.5rem 1rem 0 0;
}

.enrollment-info-table tr td {
  font-weight: bold;
  padding: 0.5rem 1rem 0 0;
}

.chart-area {
  display: flex;
  margin-top: 2rem;
}

.enrollment-title {
  font-family: NotoSansSemiBold;
  font-weight: 600;
  font-size: 20px;
  line-height: 27.24px;
  margin: 1rem 3rem;
}

.enrollment-table-container table {
  width: 40%;
  border-collapse: collapse;
}

.enrollment-table-container th,
.enrollment-table-container td {
  text-align: left;
  padding: 8px;
}