.searchEmployeeWrapper .searchComponentContainer {
    /* background-color: #f9f9f9; */
    background-color: #ffffff;
  }
  
  .tableContainer {
    padding-left: 0;
    padding-right: 0;
  }
  
  .PaginationSpanLabel {
    color: #c31d68;
    font-size: 0.8rem;
    padding-top: 1rem;
  }
  
  .confirmation-container {
    display: flex;
  
  }
  .tip-title-content{
    width:100%;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    margin-left: 16px;
  
  }
  
  .linker{
    margin-left: 24px;
    font-size: 0.875rem;
  color:#002C77;
  font-family: NotoSansBold;
  }
  
  .tri-confirmation-container {
    font-family: NotoSansLight;
    display: flex;
    background-color: #F3FFF5;
    padding: 2rem 2rem 2rem 2rem;
    border: solid 1px #ADDFB3;
    margin-bottom:2rem;
    margin-top: 2rem;
      
    
  }
  
  
  .custom_confirmation_message {
    padding-top: 9px;
    padding-right: -3px;
    margin-bottom: 0px;
    font-family: NotoSansLight;
    font-size: 1.5rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
    margin-left: 24px;
  }
  /* Added below media query to fix tablet and mobile for successpage */
  @media only screen and (max-width: 820px) {
    /* For tablet: */
    .tri-confirmation-container {
      display: grid;
      text-align: center;
  
    }
  
  }
  
   @media only screen and (max-width: 600px) {
      .tri-tip-container {
         margin-left: 2rem;
         margin-right: 2rem;    
         }
  } 
  
  
  .tip-container{
    display: flex;
    /* background-color: #FFFAEA;
    padding: 2rem 2rem 2rem 2rem */
  
  }
  
  .tri-tip-container{
        display: flex;
          background-color: #FFFAEA;
     
          border: solid 1px #FFD55A;
          margin-bottom: 2rem;
          padding: 0.625rem 1.5rem 0.0025rem 1rem
  
  }
  
  .tip-title{
    font-style: bold;
    font-family: NotoSansBold;
  color:#202020;
    font-size: #202020;
    
  }
  
  .tip-title-message {
    font-size: 0.75rem !important;
    font-family: NotoSansRegular !important;
  }
  
  .topPadding{
    justify-content: between;
    /* position: relative;
    top: 0rem; */
    /* margin-top: calc(100vh - 20rem) !important; */
    /* margin-bottom: calc(100vh - 10rem) !important; */
    /* margin-top: 50vh !important; */
    /* margin-top: 30vh !important; */
  
  }
  
  
  
  [backBtnWidth=custom] 
  {
    min-width: 6.188rem!important;
    min-height: 2.5rem;
  }
  [continueBtnWidth=custom] 
  {
    min-width: 7.37rem!important;
    min-height: 2.5rem;
  }
  
  .LifeEvent {
    padding-left: 1rem!important;
    padding-right: 2rem!important;
  }
  
  @media only screen and (max-width: 575px) {
    .LifeEvent {
      padding-left: 0rem!important;
      padding-right: 0rem!important;
    }
    /* .stepBtnWrap {
      margin: 1rem 1.6rem 1rem 1rem;
    } */
  }
  /*IPad Air*/
  @media only screen and (width: 820px) and (height: 1180px) and (orientation:portrait) {
    .topPadding{
      padding-right: 1rem;
      margin-top: 9vh !important; 
    }
    .LifeEvent {
      padding-left: 1rem!important;
      padding-right: 5rem!important;
    }
  }
  @media all and (width: 1180px) and (height: 820px) and (orientation:landscape){
    .topPadding{
      /* position: relative; */
    }
  }
  /*IPad */
  @media only screen and (width: 768px) and (height: 1024px) and (orientation:portrait) {
    .topPadding{
      padding-top: 5rem;
      margin-top: 1rem !important;
    }
  }
  @media all and (width: 1024px) and (height: 768px) and (orientation:landscape){
    .topPadding{
      padding-top: 5rem;
      margin-top: 9vh !important; 
      padding-right: 0rem !important;
    }
  }
  /*IPad Pro*/
  @media only screen and (width: 1024px) and (height: 1366px) and (orientation:portrait) {
    .topPadding{
      padding-top: 5rem;
      margin-top: 7vh !important; 
    }
  }
  @media all and (width: 1366px) and (height: 1024px) and (orientation:landscape){
    .topPadding{
      padding-top: 5rem;
      margin-top: 1rem !important;
    }
  }
  @media only screen and (min-width: 768px) {
    .formWrapper .container-fluid {
      min-height: calc(100vh - 167px);
      }
  } 
  
  