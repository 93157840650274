.custom-tableWrapper{
    padding: 0.2rem;
}
.product-title{
    float: left;
    /* width: 100%; */
    text-align: left;
    font-family: NotoSansRegular;
    font-size: 0.975rem;
    letter-spacing: 0px;
    color: #3B3B3B;
    opacity: 1;
    font-weight: bold;
    padding-top: 10px;
    padding-left: 20px; 
}
.text-right{
    text-align: right !important;
}
.button-align{
float: right;
margin: 10px;
}
table.additionalColor th:hover
{
    background-color: #0065AC !important;
    color: white;
}
.covered-members{
    font-size: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}
.view-details{
    cursor: pointer;
}

.wl-status{
    display: flex;
    justify-content: flex-end;
}