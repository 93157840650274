.add-dependent .dependent-options {
    border-bottom: 1.5px solid #DADADA;
}
.add-dependent .address {
    border-top: 1.5px solid #DADADA;
    margin: 1rem 0 1rem;
    margin-bottom: 0;

}
.add-dependent .address .address-header{
    margin-top: 1rem;
    padding-left: 0;
}
.add-dependent .address-options{
    border-bottom: 1.5px solid #DADADA;
    margin: 0.5rem 0 1rem 0;
}
.address-options .custom-control-inline{
    margin-bottom: 1rem;
}

.add-dependent input[type="radio"] + span{
    margin: 0 0.5rem;
    color: #202020;
    font-size: 0.875rem;
    font-family: "NotoSansRegular";
}
.add-dependent .modal-dialog{
    max-width: 1000px!important;
}
.radioGroup input:checked + span {
    color: #000000;
}
.add-dependent .modal-header{
    background-color: #0065AC;
    padding: 0.5rem 1.5rem;
}
.add-dependent .modal-title{
    color: white!important;
    font-size: 18px;
    line-height: 2;
    font-weight: normal;
}
.modal-footer-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.add-dependent .btn-primary{
    color: #fff;
    background-color:  #002C77;
    border-color:  #002C77;
    padding: 0.5rem 3rem;
    margin: 1rem;
}
.add-dependent .btn-primary:focus{
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}
.add-dependent .btn-primary:hover{
    color: #002C77;
    background-color: white;
    border-color: #002C77;
}

.add-dependent {
    overflow-x: hidden;
    overflow-y: auto;
}
.add-dependent .close{
    font-size: 2.5rem;
    font-weight: 100;
    text-shadow: none;
    opacity: .9;
    color: white;
}
.add-dependent .floatingLabel span {
    /* color: red; */
    color: #C53532;
}

.add-dependent .dependent.form-mandatory{
    margin-top: 1rem;
}
.form-mandatory{
    color: #C53532;
}
.address-last-row{
    border-bottom: 1.5px solid #DADADA;
    margin-bottom: 1rem;
}
.address-last-row .py-2{
    padding-bottom: 2rem!important;
}
.address-body{
    margin-top: 1rem;
}

.dependent-table th{
    background-color: rgb(0, 101, 172);
    color: rgb(255, 255, 255);
}
.dependent-table th{
    width: 18%;
}
.dependent-table th:first-of-type, .dependent-table th:last-of-type{
    width: 25%;
}
.dependent-table th:nth-child(4), .dependent-table th:nth-child(5){
    width: 25%;
}
.dependent-table td{
    vertical-align: middle;
}

.dependent-table .dependent-type{
    font-family: inherit;
    background-color: transparent;
    padding: 0.525rem 0.625rem 0.525rem 0.325rem;
    font-size: 0.875rem;    
    border: 0.063rem solid #dadada;
    outline: 0;
    background: #F4F4F4 0% 0% no-repeat padding-box;
    border: 1px solid #DADADA;
    border-radius: 4px;
    opacity: 1;
    width: 90%;
}
.dependent-table .dependent-type:disabled{
    background: #F4F4F4 0% 0% no-repeat padding-box;
    border: 1px solid #DADADA;
    border-radius: 4px;
    opacity: 1;
}

.dependent-table input[type="number"]{
    padding: 0.625rem;
    display: block;
    background-color: transparent;
    border: none;
    height: 2.5rem !important;
    border-style: solid !important;
    border-width: 0.001rem !important;
    border: #DADADA;
    border-bottom: #DADADA;
    outline: 0;
    width: 85%;
    text-align: right;
}
.dependent-table input[type="number"]:disabled{
    background: #F4F4F4 0% 0% no-repeat padding-box;
    border: 1px solid #DADADA;
    border-radius: 4px;
    opacity: 1;
}
.dependent-table input[type="checkbox"]{
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.25rem;
    border: 0.0625rem solid rgb(204, 204, 204);
    background-color: rgb(255, 255, 255);
}
.dependent-table .table-striped tbody tr:nth-of-type(odd){
    background-color: rgba(0,0,0,0)!important;
}
.dependent-table .btn-icon{
    background: none;
    border: none;
}
.dependent-table .btn-icon svg{
    width: 1rem;
    height: 1rem;
}
.dependent-table .btn-icon svg path{
    fill: #1a88e2;
 }
.dependent-table .btn-icon svg .enabled{
   fill: #1a88e2;
}
.dependent-table .btn-icon svg .disabled{
    fill: #1a88e2;
    opacity: 0.5;
 }
.dependent-table .alert{
    background-color: #C53532;
    color: white;
}
.add-dependent .floatingLabel  .calendarIcon{
    position: absolute;
    right: 0.75rem;
    top: 0.75rem!important;
    color: #002C77;
    pointer-events: none;
    width: 1rem;

}
.add-dependent .DOB{
    position: relative;
}
.dependent-table .benf-saveBtn{
    float: right;
}
#addDependent .selectContainer, .add-dependent .selectContainer
{
    padding-top: 0;
}
#addDependent .selectContainer .selectText, .add-dependent .selectContainer .selectText
{
    height: 2.5rem;
}
#addDependent .selectContainer label, .add-dependent .selectContainer label
{
    margin-bottom: 0.3rem;
}
#addDependent .isRequired, .add-dependent .isRequired
{
    border-color: inherit;
}
#addDependent .floatingLabel  .calendarIcon{
    top: 3.45rem;
}

.text-success-contrast {
    color: #14853D !important;
}