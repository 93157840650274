.product-slider {
    display: flex;
    justify-content: space-between;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.product-slides-area {
    width: 100%;
    margin: 0 1rem;
    overflow: hidden;
}

.product-slides-list {
    left: 0;
    gap: 1.5rem;
    display: flex;
    position: relative;
    flex-direction: row;
    transition: left 0.4s ease;
}

.product-item {
    min-width: 290px;
    text-align: center;
    border-radius: 4px;
    padding: 1.5rem 1.875rem;
    border: 1px solid #D1E0EC;
    background-color: #F5FFFD;
}

.product-item > .product-logo {
    max-width: 200px;
    max-height: 48px;
}

.product-item > .product-dummy {
    width: 200px;
    height: 48px;
}

.product-item > .product-name {
    font-size: 1rem;
    font-weight: bold;
}

.slide-btn {
    padding: 0;
    border: none;
    cursor: pointer;
    background-color: transparent;
}

.slide-btn.btn-left > img {
    transform: rotate(180deg);
}