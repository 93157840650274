.floatingLabel {
  position: relative;
  font-size: 0.875rem;
  color: #999;
  font-family: NotoSansMedium;
}

/* .floatingLabel .required {
  border-bottom: 0.0625rem solid #f9b44d !important;
} */
/* 
.floatingLabel .required {
  border-bottom: 0.0625rem solid;
} */

.floatingLabel textarea {
  padding: 0.625rem;
  display: block;
  background-color: transparent;
  border: none;
  border-bottom: 0.0625rem solid #ccc;
}

/* .floatingLabel textarea:focus {
  outline: none;
  box-shadow: 0 0 1rem #ddd;
} */


.floatingLabel label {
  font-size: 0.875rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0.2rem;
  top: 0.6rem;
  transition: 0.2s ease all;
}

.floatingLabel .isFocused {
  transform: translateY(-1.5rem) scale(1);
}

.elementDisabled {
  background-color: #f3f3f3 !important;
}

/* .displayContent{
  white-space: pre-line;
  background-color: #999;

} */

.validation {
  color: red;
}

textarea {
  /* color: red; */
  /* background-color: blue; */
  /* background-color:  #F4F4F4 !important; */
  border: 1px solid #DADADA !important;
  padding: 1rem !important;
  overflow-y: none !important;
  overflow-x: none !important;
    }

    #section1{
      overflow-y: auto !important;
      overflow-x: auto !important;
      background-color:  #F4F4F4 !important;

    }

    .divScroll {
      overflow:scroll;
      height:400px;
      width:600px;
      }
      .hideElement + .error-message {
        display:none;
    }