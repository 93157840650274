.checkboxContainer .regularCheckbox {
  -webkit-appearance: none;
  background-color: #fff;
  border: 0.0625rem solid #ccc;
  border-radius: 0.25rem;
  display: inline-block;
  position: relative;
}

.checkboxContainer .regularCheckbox:checked:before {
  position: absolute;
  left: 0.0625rem;
  bottom: 0.1875rem;
  height: 40%;
  width: 0.125rem;
  background-color: #1a88e2;
  content: '';
  transform: translateX(0.375rem) rotate(-45deg);
  transform-origin: left bottom;
}

.checkboxContainer .regularCheckbox:checked:after {
  position: absolute;
  left: 0.0625rem;
  bottom: 0.1875rem;
  height: 0.125rem;
  width: 75%;
  background-color: #1a88e2;
  content: '';
  transform: translateX(0.375rem) rotate(-45deg);
  transform-origin: left bottom;
}

.checkboxContainer .checkboxLabel {
  vertical-align: text-bottom;
  padding-left: 0.5rem;
  padding-bottom: 0.2rem;
  font-size: 0.875rem;
  color: #666;
}

.checkboxContainer .checkboxLabel.checked {
  color: #000;
}
.checkboxContainer .custom-class{
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkboxContainer .custom-class label{
  margin-left: 0.5rem;
  margin-bottom: 0!important;
}

.checkboxContainer input[type='checkbox']:disabled {
  background-color: #f3f3f3 !important;
}

.checkBoxAndLabel label{
  margin-left: 0.5rem;
  vertical-align: top;
}
.ProductSelectioncol-12 .checkBoxAndLabel{
  margin-left: 1rem;
}